import React from "react";
import s from "../assets/scss/HorizontalScroll.module.scss";

import useWindowSize from "../hooks/useWindowSize";
import { breakpoints } from "../types";

export default function HorizontalScroll({ children, gradientBg = false }) {
  const windowSize = useWindowSize();

  return (
    <>
      {windowSize.width <= breakpoints.tablet ? (
        <div>{children}</div>
      ) : (
        <div
          className={s.horizontalScroll}
          data-h-scroll
          data-bg-gradient={gradientBg}
        >
          {children}
        </div>
      )}
    </>
  );
}
