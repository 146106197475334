export const en = {
  translation: {
    catalog: "view all products",
    hero: {
      l1: "Say hello to",
      l2: "vegan & gentle skin,",
      l3: "hair and body care for",
      l4: "adults, babies & kids!",
    },
    slider: {
      s1: {
        title: "I'm Vegan",
        text: {
          p1: "Because I love animals and we care about them! I’m lovingly and proudly free of any animal-derived ingredients.",
          p2: "So we are committed to making products that could potentially hurt any of them.",
        },
      },
      s2: {
        title: "I’m SLS, SLES & Paraben Free!",
        text: {
          p1: "You won’t find SLS, SLES and paraben.",
          p2: "My balanced, impactful formulas are free from most commonly used skin irritants and preservatives. So we keep them out! Bye-bye!",
        },
        small: "* Except for our cleansing tea tree oil Hand Wash, which is still free from paraben.",
      },
      s3: {
        title: "I’m Simple, Gentle & Fun",
        text: {
          p1: "Skin-loving, playful formulas that deliver real, effective results. I’m harvested with simple, plant-based, impactful extracts for little ones and grown-ups.",
          p2: "Plus, I’m dermatologically tested! Try me and you will see!",
        },
      },
      s4: {
        title: "Embrace the Tiny Green Spirit!",
        text: {
          p1: "Let’s play. Essential, vegan, reliable. Say goodbye to boring products and say hello to my tiny, big world!",
        },
      },
    },
    titleAndDesc: {
      preTitle: "Lychee | ",
      home: {
        title: "Digital talent agency.",
        desc: "Lychee is a digital influencer marketing agency that is a solution partner for iconic beands on a global scale.",
        canonical: "https://lycheedigital.co",
      },
      creators: {
        title: "Creators",
        desc: "Join Lychee's team of creative content creators.",
        canonical: "https://lycheedigital.co/creators",
      },
      brands: {
        title: "Brands",
        desc: "Extraordinary creativity, global perspective. We are building the campaign of your dreams in the most creative way.",
        canonical: "https://lycheedigital.co/brands",
      },
      services: {
        title: "Services",
        desc: "We meet all your creative marketing and new media needs.",
        canonical: "https://lycheedigital.co/services",
      },
      partners: {
        title: "Partners",
        desc: "We partner with the best brands.",
        canonical: "https://lycheedigital.co/partners",
      },
      about: {
        title: "About",
        desc: "We are a digital marketing agency that houses creative and strategic talents.",
        canonical: "https://lycheedigital.co/about",
      },
      contact: {
        title: "Contact",
        desc: "Let's take over your digital world.",
        canonical: "https://lycheedigital.co/contact",
      },
    },
    notFound: { text: "Page Not Found", button: "Back to Homepage" },
    takeAStep: { p1: "take a", p2: "step", p3: "forward..!", rotating: "tiny" },
    adult: {
      title: "ADULT",
      pill: "Grown-ups",
      desc: "Daily care formulas for grown-ups made with simple, vegan ingredients that work!",
      button: "view all",
    },
    kids: {
      title: { p1: "BABIES &", p2: "KIDS" },
      pill: "Babies & Kids",
      desc: "We make happy skin care essentials for little ones packed with tiny, big love.",
      button: "view all",
    },
    footer: {
      rights: "©2022 Tiny Green by Honnes. All Rights Reserved.",
      links: {
        l1: "Cookie Policy",
        l2: "Terms of Use",
        l3: "GDPR",
        l4: "Quality Policy",
        l5: "Product Catalogue",
      },
    },
    productDetail: {
      close: "close",
      filter: {
        all: "all",
        grownUps: "Grown-ups",
        babiesAndKids: "Babies & Kids",
      },
      downloadPdf: "Product PDF",
    },
    privacyPopup: {
      text: "This website uses cookies to ensure you get the best experience on our website.",
      btnText: "Understood",
      cookiePolicy: "Cookie Policy",
    },
    stickyButton: "Watch the trailer",
  },
}
