import React from "react"
import s from "../assets/scss/ProductCard.module.scss"

import cx from "classnames"

import Img from "./Img"
import StickerVegan from "./icons/StickerVegan"
import useWindowSize from "../hooks/useWindowSize"
import { breakpoints } from "../types"

export default function ProductCard({
  product = {
    imgRotation: 0,
    imgMarginBottom: "-25%",
    bgColor: "",
    label: "label",
    title: "title",
    smTitle: false,
    small: "small",
    features: {
      borderColor: "",
      bgColor: "",
      textColor: "",
      highlightedItemIndex: "",
      items: ["feature"],
    },
    stickerTop: "",
    stickerBottom: { textColor: "" },
    image: "",
  },
}) {
  const windowSize = useWindowSize()

  return (
    <div className={s.productCard} style={{ background: product.bgColor }}>
      <div
        className={s.imgWrapper}
        style={{
          transform: `rotate(${product.imgRotation}deg)`,
          marginBottom: windowSize.width <= breakpoints.mobile && product.imgMarginBottom,
        }}
      >
        <Img src={product.image} objectFit="contain"></Img>
      </div>
      <div className={s.text}>
        {product.label && (
          <h5 className={s.label} style={{ color: product.features.bgColor }}>
            {product.label}
          </h5>
        )}
        <h2 className={cx(s.title, { [s.sm]: product.smTitle })}>{product.title}</h2>
        <small className={s.small}>{product.small}</small>
        <ul className={s.features}>
          {product.features.items.map((item, i) => {
            return (
              <li
                className={cx(s.feature, {
                  [s.highlighted]: product.features.highlightedItemIndex === i,
                })}
                key={i}
              >
                <div
                  className={s.bg}
                  style={{
                    background: product.features.highlightedItemIndex === i ? product.features.bgColor : "transparent",

                    border: `1px solid ${product.features.borderColor}`,
                  }}
                ></div>
                <p
                  className={s.featureText}
                  style={{
                    color: product.features.highlightedItemIndex === i ? "#fff" : product.features.textColor,
                  }}
                >
                  {item}
                </p>
              </li>
            )
          })}
        </ul>
      </div>

      {product.stickerTop && (
        <div className={s.stickerTop}>
          <Img src={product.stickerTop} objectFit={"contain"}></Img>
        </div>
      )}

      <div className={s.stickerBottom}>
        <StickerVegan textColor={product.stickerBottom.textColor}></StickerVegan>
      </div>
    </div>
  )
}
