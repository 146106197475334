import React from "react"
import s from "../assets/scss/Footer.module.scss"

import cx from "classnames"
import { useTranslation } from "react-i18next"

import clickForEn from "../assets/img/click-for-info-en.svg"
import clickForTr from "../assets/img/click-for-info-tr.svg"
import logoHonnes from "../assets/img/horizontal/honnes-logo.svg"
import tinyCharacter from "../assets/img/horizontal/tiny-character.svg"
import mouse from "../assets/img/mouse.svg"
import qLeft from "../assets/img/q-left.svg"
import qRight from "../assets/img/q-right.svg"
import stickerGroupDesktop from "../assets/img/horizontal/sticker-group-desktop.svg"
import stickerGroupTouch from "../assets/img/horizontal/sticker-group-touch.svg"
import watsons from "../assets/img/only-on-watsons.svg"

import { breakpoints, lngs } from "../types"
import LanguageSelect from "./LanguageSelect"
import useWindowSize from "../hooks/useWindowSize"
import Img from "./Img"

const Footer = () => {
  const { i18n, t } = useTranslation()
  const windowSize = useWindowSize()

  return (
    <footer className={cx(s.footer)}>
      <div className={s.moreInfo}>
        <div className={s.top}>
          <div className={s.honnes}>
            <Img src={logoHonnes} objectFit="contain"></Img>
          </div>
          <a className={s.email} href="mailto:info@honnes.com">
            info@honnes.com
          </a>
        </div>

        <div className={s.clickFor}>
          {i18n.language === lngs.en.nativeName ? (
            <Img src={clickForEn} objectFit="contain"></Img>
          ) : (
            <Img src={clickForTr} objectFit="contain"></Img>
          )}
        </div>

        <div className={s.tinyCharWrapper}>
          <Img src={tinyCharacter} objectFit="contain"></Img>
        </div>

        <div className={s.honnesLink}>
          <div className={cx(s.decoration, s.qLeft)}>
            <Img src={qLeft} objectFit="contain"></Img>
          </div>
          <div className={cx(s.decoration, s.qRight)}>
            <Img src={qRight} objectFit="contain"></Img>
          </div>
          <div className={cx(s.decoration, s.mouse)}>
            <Img src={mouse} objectFit="contain"></Img>
          </div>
          <a className={s.link} href="http://www.honnes.com" target="_blank" rel="noreferrer">
            www.honnes.com
          </a>
        </div>
      </div>

      <div className={s.legal}>
        <div className={s.links}>
          <a
            className={s.link}
            href={
              i18n.language === lngs.en.nativeName
                ? "https://thetinygreen.com/pdf/cerez-politikasi-en.pdf"
                : "https://thetinygreen.com/pdf/cerez-politikasi-tr.pdf"
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("footer.links.l1")}
          </a>
          <a
            className={s.link}
            href={
              i18n.language === lngs.en.nativeName
                ? "https://thetinygreen.com/pdf/kullanim-kosullari-en.pdf"
                : "https://thetinygreen.com/pdf/kullanim-kosullari-tr.pdf"
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("footer.links.l2")}
          </a>
          <a
            className={s.link}
            href={
              i18n.language === lngs.en.nativeName
                ? "https://thetinygreen.com/pdf/kvkk-aydinlatma-metni-en.pdf"
                : "https://thetinygreen.com/pdf/kvkk-aydinlatma-metni-tr.pdf"
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("footer.links.l3")}
          </a>
          <a
            className={s.link}
            href={
              i18n.language === lngs.en.nativeName
                ? "https://thetinygreen.com/pdf/kalite-politikasi-honnes-en.pdf"
                : "https://thetinygreen.com/pdf/kalite-politikasi-honnes-tr.pdf"
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("footer.links.l4")}
          </a>
          <a
            className={s.link}
            href={
              i18n.language === lngs.en.nativeName
                ? "https://thetinygreen.com/pdf/tinygreen-en-digital-catalogue.pdf"
                : "https://thetinygreen.com/pdf/tinygreen-tr-dijital-katalog.pdf"
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("footer.links.l5")}
          </a>
        </div>

        <div className={cx(s.lngC, s.mobile)}>
          <LanguageSelect />
        </div>

        <a
          className={s.watsonsWrapper}
          href="https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.watsons.com.tr%2Ftiny-green%2Fb%2F3509%3Futm_source%3Dtinygreen%26utm_medium%3Dwebsite%26utm_campaign%3Dtinygreenref&data=05%7C01%7Carmagan.senol%40justdesignfx.com%7C16315560eb3a41666da108dbba7cfee4%7C5f834ae1f85348758ef24b6c5a7fda98%7C0%7C0%7C638308818311365241%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=jLi3Z%2B3XFGF%2BXzs4wc090SYNChmiT93xKt76pbcylJo%3D&reserved=0"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Img src={watsons} objectFit="contain"></Img>
        </a>

        <div className={s.copyright}>
          <small className={s.cText}>{t("footer.rights")}</small>
          <small className={s.madeBy}>
            Made by{" "}
            <a className={s.jdfx} href="https://www.justdesignfx.com" target="_blank" rel="noreferrer">
              JUST DESIGN FX
            </a>
          </small>
        </div>
      </div>

      <div className={cx(s.lngC, s.desktop)}>
        <LanguageSelect />
      </div>

      <div className={s.stickerGroup}>
        <Img
          src={windowSize.width > breakpoints.tablet ? stickerGroupDesktop : stickerGroupTouch}
          objectFit="contain"
        ></Img>
      </div>
    </footer>
  )
}

export default Footer
