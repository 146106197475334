export const tr = {
  translation: {
    routes: {
      home: {
        ui: "",
        path: "",
      },
      products: {
        ui: "products",
        path: "products",
      },
    },
    catalog: "tüm ürünleri görüntüle",
    hero: {
      l1: "Vegan, etkili ve",
      l2: "hassas formüllere ",
      l3: "sahip bakım",
      l4: "deneyimini keşfet!",
    },
    slider: {
      s1: {
        title: "Hayvan Dostu & Vegan Felsefe",
        text: {
          p1: "Hayvanları çok seviyoruz ve önemsiyoruz. Biz canlı dostu bir aileyiz. Ürünlerimizde hayvansal veya hayvansal türevli bileşenler kullanmadık.",
          p2: "Tüm ürünlerimiz vegan formüller içerir ve hayvanlar üzerinde hiçbir test gerçekleştirilmemiştir.",
        },
      },
      s2: {
        title: "Sülfat (SLS & SLES) ve  Paraben <br/> İçermeyen Formüller",
        text: {
          p1: "Ürünlerimizin içeriğinde SLS & SLES gibi sülfat ve paraben bulunmaz.",
          p2: "Yüksek performanslı formüllerimiz, cildimize etkili, hassas ve dengeli bakım için özel olarak tasarlandı!",
        },
        small: "* Yalnızca tiny green Sıvı El Sabunu SLS & SLES içerir.",
      },
      s3: {
        title: "Etkili, Sürdürülebilir, Minimal",
        text: {
          p1: "Minimal marka yaklaşımı ve eğlenceli tasarımlar! Sürdürülebilir, doğa ve hayvan dostu vegan içerikler ile etkili, güvenli ve yüksek performanslı formüller!",
          p2: "Tüm ürünlerimiz dermatolojik olarak test edilmiştir!",
        },
      },
      s4: {
        title: "tiny green Dünyasını Keşfet!",
        text: {
          p1: "Miniklerin yeni bakım arkadaşıyla tanışın! Küçük adımlarla büyük değişimler yaratın!",
        },
      },
    },
    titleAndDesc: {
      preTitle: "Lychee | ",
      home: {
        title: "Digital talent agency.",
        desc: "Lychee global ölçekte ikonik markaların çözüm ortağı olan dijital influencer marketing ajansıdır.",
        canonical: "https://lycheedigital.co",
      },
      creators: {
        title: "Üreticiler",
        desc: "Lychee’nin kreatif içerik üreticileri ekibine katılın.",
        canonical: "https://lycheedigital.co/ureticiler",
      },
      brands: {
        title: "Markalar",
        desc: "Olağanüstü yaratıcılık, küresel bakış açısı. Hayallerinizdeki kampanyayı en kreatif şekilde inşa ediyoruz.",
        canonical: "https://lycheedigital.co/markalar",
      },
      services: {
        title: "Hizmetler",
        desc: "Tüm yaratıcı marketing ve yeni medya ihtiyaçlarınızı karşılıyoruz.",
        canonical: "https://lycheedigital.co/hizmetler",
      },
      partners: {
        title: "Partnerler",
        desc: "En iyi markaların partnerliğini yapıyoruz.",
        canonical: "https://lycheedigital.co/partnerler",
      },
      about: {
        title: "Hakkımızda",
        desc: "Yaratıcı ve stratejik yetenekleri bünyesinde barındıran dijital marketing ajansıyız.",
        canonical: "https://lycheedigital.co/hakkımızda",
      },
      contact: {
        title: "İletişim",
        desc: "Dijital dünyanızı devralalım.",
        canonical: "https://lycheedigital.co/iletisim",
      },
    },
    notFound: { text: "Aradığınız Sayfa Bulunamadı", button: "Anasayfaya Dön" },
    takeAStep: { p1: "tiny", p2: "dünyasını", p3: "keşfet..!", rotating: "green" },
    adult: {
      title: "YETİŞKİN",
      pill: "Yetişkin",
      desc: "Yetişkinler için vegan ve etkili içerikler ile tasarlanmış eşsiz bakım!",
      button: "tümünü <br/> gör",
    },
    kids: {
      title: { p1: "BEBEK &", p2: "ÇOCUK" },
      pill: "Bebek & Çocuk",
      desc: "Minikler için vegan, güvenli ve hassas formüller ile eğlenceli bakım!",
      button: "tümünü <br/> gör",
    },
    footer: {
      rights: "©2022 Tiny Green, Honnes. Tüm hakları saklıdır.",
      links: {
        l1: "Çerez Politikası",
        l2: "Kullanım Koşulları",
        l3: "Aydınlatma Metni",
        l4: "Kalite Politikası",
        l5: "Ürün Kataloğu",
      },
    },
    productDetail: {
      close: "kapat",
      filter: {
        all: "tümü",
        grownUps: "Yetişkin",
        babiesAndKids: "Bebek & Çocuk",
      },
      downloadPdf: "Ürün PDF'i",
    },
    privacyPopup: {
      text: "Websitemiz, en iyi deneyimi yaşamanızı sağlamak amacıyla çerezleri kullanır.",
      btnText: "Anladım",
      cookiePolicy: "Çerez Politikası",
    },
    stickyButton: "Tanıtım filmini izle",
  },
}
