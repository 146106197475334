import React, { useState } from "react";
import s from "../assets/scss/Img.module.scss";

import cx from "classnames";

export default function Img({
  src = "src",
  alt = "alt",
  objectFit = "cover",
  objectPosition = "center",
  cls = null,
}) {
  const [isLoaded, setIsLoaded] = useState(null);

  return (
    <img
      onLoad={() => {
        setIsLoaded(true);
      }}
      className={cx(s.img, cls, { [s.visible]: isLoaded })}
      src={src}
      alt={alt}
      style={{ objectFit, objectPosition }}
    />
  );
}
