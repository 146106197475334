import s from "../assets/scss/FixedContainer.module.scss"
import React, { useRef } from "react"

import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import cn from "classnames"

import bgBooklet from "../assets/img/bg-booklet.svg"
import useWindowSize from "../hooks/useWindowSize"
import { lngs } from "../types"
import StickyWatch from "./StickyWatch"

export default function FixedContainer() {
  const fixedRef = useRef(null)
  const imgRef = useRef(null)
  const bookletRef = useRef(null)
  const windowSize = useWindowSize()
  const { i18n } = useTranslation()
  const location = useLocation()

  return (
    <div
      className={cn(s.fixedContainer, { [s.hidden]: location.pathname !== "/" })}
      style={{
        width: windowSize.innerWidth,
        height: windowSize.innerHeight,
      }}
      ref={fixedRef}
    >
      <Link
        to={i18n.language === lngs.en.nativeName ? "/products" : "/urunler"}
        className={s.bookletWrapper}
        ref={bookletRef}
      >
        <svg
          className={s.booklet}
          src={bgBooklet}
          alt="Background Shape"
          ref={imgRef}
          xmlns="http://www.w3.org/2000/svg"
          width="124.911"
          height="124.858"
          viewBox="0 0 124.911 124.858"
        >
          <path
            data-booklet-path
            id="Path_166"
            data-name="Path 166"
            d="M298.567,444.7c.537-2.172,1.212-4.216,1.533-6.313,1.32-8.625-3.417-14.989-12.053-16.184a34.479,34.479,0,0,0-5.719-.12c-.859.025-1.185-.169-1.357-1.036a33.073,33.073,0,0,0-1.263-4.886c-2.211-6.071-6.723-9.538-12.589-9.643-3.678-.066-6.936,1.341-10,3.253-.683.426-1.355.87-2.052,1.319-4.082-4.849-8.617-8.848-15.388-7.953-6.738.891-10.087,5.945-12.722,11.4-2.172-.541-4.172-1.2-6.225-1.524-8.686-1.378-15.09,3.362-16.3,12.062a27.509,27.509,0,0,0-.121,5.445c.07,1.131-.254,1.472-1.326,1.659a26.992,26.992,0,0,0-4.616,1.212c-6.052,2.214-9.527,6.74-9.613,12.612-.059,4.03,1.618,7.517,3.779,10.8.264.4.531.8.632.953a78.078,78.078,0,0,0-5.4,6.248,12.766,12.766,0,0,0-.094,15.214,20.588,20.588,0,0,0,7.947,6.321c.431.212.859.434,1.161.586-.549,2.206-1.207,4.207-1.53,6.261-1.364,8.679,3.392,15.08,12.1,16.27a32.938,32.938,0,0,0,5.583.118c.9-.031,1.312.134,1.442,1.09a24.235,24.235,0,0,0,.777,3.464c2.835,9.764,11.345,13.579,20.485,9.159,1.586-.767,3.073-1.74,4.606-2.616,9,11.964,22.508,10.259,28.218-3.536,2.075.515,4.116,1.184,6.211,1.512,7.848,1.227,14.1-2.66,15.806-10a34.946,34.946,0,0,0,.536-7.586c.008-1.07.269-1.391,1.273-1.576a28.889,28.889,0,0,0,4.618-1.207c6.08-2.205,9.523-6.688,9.678-12.565a16.254,16.254,0,0,0-2.16-8.1c-.721-1.356-1.557-2.651-2.337-3.967C314,463.642,312.522,450.834,298.567,444.7Z"
            transform="translate(-185.205 -403.01)"
            fill="#e5f5df"
          />
        </svg>
        <p className={s.text}>
          {i18n.language === lngs.en.nativeName ? (
            <>
              view all <br /> products
            </>
          ) : (
            <>
              tüm ürünleri <br /> görüntüle
            </>
          )}
        </p>
      </Link>

      <StickyWatch />
    </div>
  )
}
