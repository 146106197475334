import React, { useCallback, useEffect, useRef } from "react"
import { useResizeDetector } from "react-resize-detector"
import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar"
import useWindowSize from "../hooks/useWindowSize"
import { useTranslation } from "react-i18next"

import { breakpoints } from "../types"

import { useLastScrollYStore } from "../stores/lastScrollYStore"
import useHeroAnimationStore from "../stores/heroAnimationStore"
import { useModalStore } from "../stores/modalStore"

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const WithSmooth = ({ children, location }) => {
  const scrollerRef = useRef(null)
  const contentRef = useRef(null)
  const smoothScrollbar = useRef(null)

  const q = gsap.utils.selector(contentRef)
  const size = useWindowSize()
  const lastScrollYStore = useLastScrollYStore()
  const { reverse } = useHeroAnimationStore()
  const { i18n } = useTranslation()
  const modalStore = useModalStore()

  const onResize = useCallback(() => {
    ScrollTrigger.refresh()
    console.log("SCROLLTRIGGER REFRESHED")
  }, [])

  useResizeDetector({ targetRef: contentRef, onResize })

  const clearAnims = () => {
    ScrollTrigger.getAll().forEach((instance) => {
      instance.kill()
    })

    // This in case a scroll animation is active while the route is updated
    gsap.killTweensOf(window)
  }

  const lockScrollbar = () => {
    smoothScrollbar.current && smoothScrollbar.current.updatePluginOptions("modal", { open: true })
    console.log("SCROLLBAR LOCKED")
  }

  const unlockScrollbar = () => {
    smoothScrollbar.current && smoothScrollbar.current.updatePluginOptions("modal", { open: false })
    console.log("SCROLLBAR UNLOCKED")
  }

  const checkArray = (arr) => {
    return Array.isArray(arr) && arr.length !== 0
  }

  const updateMarkers = () => {
    // Only necessary to correct marker position - not needed in production
    if (document.querySelector(".gsap-marker-scroller-start")) {
      const markers = gsap.utils.toArray('[class *= "gsap-marker"]')

      smoothScrollbar.current.addListener(({ offset }) => {
        gsap.set(markers, { marginTop: -offset.y })
      })
    }
  }

  // INITIALIZE ANIMATIONS
  const initAnimations = (smoothInstance) => {
    // HIDE SCROLLBAR
    const thumbs = document.querySelectorAll(".scrollbar-track")
    if (location.pathname.includes("products") || location.pathname.includes("urunler")) {
      thumbs.forEach((item) => {
        item.style.visibility = "hidden"
      })
    } else {
      thumbs.forEach((item) => {
        item.style.visibility = "visible"
      })
    }

    // STICKY ITEM
    if (checkArray(q("[data-sticky-item]"))) {
      q("[data-sticky-item]").forEach((item) => {
        smoothInstance?.addListener(({ offset }) => {
          gsap.to(item, {
            y: () => offset.y + "px",
            x: () => offset.x + "px",
            ease: "none",
            duration: 0,
          })
        })
      })
    }

    // STICKY WATCH BUTTON
    const stickyWatch = document.querySelector("[data-sticky-watch]")
    const container = document.querySelector("[data-tiny-step]")
    if (stickyWatch) {
      gsap.set(stickyWatch, {
        autoAlpha: 0,
      })

      ScrollTrigger.create({
        // markers: true,
        trigger: container,
        start: "top bottom",
        end: "bottom bottom",
        onEnter: () => {
          gsap.to(stickyWatch, {
            autoAlpha: 1,
            duration: 0.2,
          })
        },
        onEnterBack: () => {
          gsap.to(stickyWatch, {
            autoAlpha: 1,
            duration: 0.2,
          })
        },
        onLeave: () => {
          gsap.to(stickyWatch, {
            autoAlpha: 0,
            duration: 0.2,
          })
        },
        onLeaveBack: () => {
          gsap.to(stickyWatch, {
            autoAlpha: 0,
            duration: 0.2,
          })
        },
      })

      // gsap.to(stickyWatch, {
      //   scrollTrigger: {
      //     markers: true,
      //     trigger: container,
      //     onEnter: () => {
      //       console.log("enter")
      //       // gsap.set(stickyWatch, {
      //       //   autoAlpha: 1,
      //       // })
      //     },
      //     onEnterBack: () => {
      //       console.log("enter back")

      //       // gsap.set(stickyWatch, {
      //       //   autoAlpha: 1,
      //       // })
      //     },
      //     onLeave: () => {
      //       console.log("leave")
      //       // gsap.set(stickyWatch, {
      //       //   autoAlpha: 0,
      //       // })
      //     },
      //     onLeaveBack: () => {
      //       console.log("leave back")

      //       // gsap.set(stickyWatch, {
      //       //   autoAlpha: 0,
      //       // })
      //     },
      //   },
      // })
    }

    // CURVED MARQUEE
    const curvedMarquee = document.querySelector("[data-curved-marquee]")
    if (curvedMarquee) {
      smoothInstance?.addListener(({ offset }) => {
        smoothInstance?.isVisible(curvedMarquee) &&
          curvedMarquee.setAttribute("startOffset", ((offset.y.toFixed(2) - size.width) / 2.5) * -1)
      })
    }

    // HORIZONTAL SCROLL
    if (size.width > breakpoints.tablet) {
      if (checkArray(q("[data-h-scroll]"))) {
        let maxWidth = 0
        const container = document.querySelector("[data-h-scroll]")

        const getMaxWidth = () => {
          maxWidth = 0
          q("[data-h-scroll-section]").forEach((section) => {
            maxWidth += section.clientWidth
          })
        }
        getMaxWidth()
        ScrollTrigger.addEventListener("refreshInit", getMaxWidth)

        const thumb = document.querySelector("[data-hscroll-scrollbar-thumb]")
        const hscrollScrollbar = document.querySelector("[data-hscroll-scrollbar]")

        gsap.to(q("[data-h-scroll-section]"), {
          x: () => `-${maxWidth - window.innerWidth}`,
          ease: "none",
          scrollTrigger: {
            trigger: container,
            end: () => `+=${maxWidth}`,
            onUpdate: (self) => {
              if (hscrollScrollbar) {
                gsap.to(thumb, {
                  duration: 0.1,
                  scaleX: self?.progress,
                  // width: `${
                  //   hscrollScrollbar?.clientWidth * self?.progress.toFixed(2)
                  // }px`,
                })
              }
            },
            scrub: true,
            pin: true,
            invalidateOnRefresh: true,
            // markers: true,
          },
        })
      }
    }

    // PARALLAX ITEM
    if (size.width > breakpoints.mobile) {
      if (checkArray(q("[data-parallax]"))) {
        const parallaxItems = q("[data-parallax]")

        parallaxItems?.forEach((item) => {
          if (item.dataset.horizontal === true) {
            gsap.to(item, {
              xPercent: () => -100 * item.dataset.speed,
              scrollTrigger: {
                trigger: item,
                scrub: true,
                // markers: true,
              },
            })
          } else if (item.dataset.horizontal === false || item.dataset.horizontal === undefined) {
            gsap.to(item, {
              yPercent: () => -100 * item.dataset.speed,
              scrollTrigger: {
                trigger: item,
                scrub: true,
              },
            })
          }
        })
      }
    }

    // CATALOG BG COLOR CHANGE
    const catalogBg = document.querySelector("[data-booklet-path]")
    if (catalogBg) {
      gsap.to(catalogBg, {
        fill: "#fff",
        scrollTrigger: {
          trigger: document.querySelector("[data-h-scroll]"),
          // markers: true,
          scrub: 1,
        },
      })
    }

    // TRACK SCROLL Y ON ROUTE CHANGE
    const trackClick = document.querySelectorAll("[data-track-scroll-y]")

    const getScrollY = () => {
      if (smoothInstance) {
        lastScrollYStore.setLastY(smoothInstance.offset.y)
      } else {
        console.log("lol")
        lastScrollYStore.setLastY(window.scrollY)
      }
    }

    trackClick?.forEach((item) => {
      item.addEventListener("click", getScrollY)
    })

    if (location.pathname === "/") {
      if (smoothInstance) {
        smoothInstance.scrollTo(0, lastScrollYStore.lastScrollY.y, 0)
      } else {
        window.scrollTo(0, lastScrollYStore.lastScrollY.y)
      }
    } else {
      if (smoothInstance) {
        smoothInstance.scrollTo(0, 0, 0)
      } else {
        window.scrollTo(0, 0)
      }
    }

    // REVERSE HERO ANIM ON TO
    const hero = document.querySelector("[data-hero]")
    if (hero) {
      hero.style.pointerEvents = "auto"

      const listener = ({ offset }) => {
        if (offset.y > 10) {
          hero.style.pointerEvents = "none"
        } else {
          hero.style.pointerEvents = "auto"
        }

        if (offset.y === 0 && smoothInstance?.isVisible(hero)) {
          reverse()
        }
      }

      if (location.pathname === "/") {
        smoothInstance?.addListener(listener)
      } else {
        smoothInstance?.removeListener(listener)
      }
    }

    //Pinned Section
    // if (checkArray(q("[data-pinned]"))) {
    //   if (checkArray(q("[data-pinned-item]"))) {
    //     q("[data-pinned-item]").forEach((item, i) => {
    //       gsap.set(item, {
    //         zIndex: i + 1,
    //         yPercent: () => (i === 0 ? 0 : 200),
    //       });

    //       const tl = gsap.timeline({
    //         scrollTrigger: {
    //           trigger: q("[data-pinned]"),
    //           start: () => `top -${item.clientHeight * i}`,
    //           end: () => `+=${item.clientHeight}`,
    //           scrub: 1,
    //           invalidateOnRefresh: true,
    //           // markers: true,
    //         },
    //       });

    //       tl.add("fadeOut").to(
    //         item,
    //         {
    //           yPercent: 0,
    //         },
    //         "fadeOut"
    //       );
    //     });

    //     ScrollTrigger.create({
    //       trigger: q("[data-pinned]"),
    //       scrub: true,
    //       pin: true,
    //       start: () => "center center",
    //       end: () =>
    //         "+=" +
    //         (q("[data-pinned-item]").length + 1) *
    //           q("[data-pinned-item]")[0]?.clientHeight,
    //       invalidateOnRefresh: true,
    //       // markers: true,
    //     });
    //   }
    // }
  }

  const initSmooth = () => {
    class ModalPlugin extends ScrollbarPlugin {
      static pluginName = "modal"

      static defaultOptions = {
        open: false,
      }

      transformDelta(delta) {
        return this.options.open ? { x: 0, y: 0 } : delta
      }
    }

    Scrollbar.use(ModalPlugin)

    // Init smooth scroll
    const scroller = scrollerRef.current

    smoothScrollbar.current = Scrollbar.init(scrollerRef.current, {
      damping: 0.08,
      delegateTo: document,
      alwaysShowTracks: true,
      renderByPixels: true,
      syncCallbacks: true,
    })
    console.log("%c Smooth Initialized", "background: #222; color: #bada55")

    ScrollTrigger.scrollerProxy("[data-scroller]", {
      scrollTop(value) {
        if (arguments.length) {
          smoothScrollbar.current.scrollTop = value
        }
        return smoothScrollbar.current.scrollTop
      },
    })

    smoothScrollbar.current.addListener(ScrollTrigger.update)
    ScrollTrigger.defaults({ scroller })

    initAnimations(smoothScrollbar.current)
    updateMarkers()
  }

  useEffect(() => {
    // INIT ANIMATIONS WITH OR WITHOUT SMOOTH
    if (size.width <= breakpoints.tablet) {
      initAnimations(null)
      return
    }

    if (smoothScrollbar.current) {
      smoothScrollbar.current.destroy()
      console.log("%c Smooth Destroyed", "background: #222; color: blue")
    }

    initSmooth()

    const hero = document.querySelector("[data-hero]")

    const controlPointerEvents = (e) => {
      if (window.pageYOffset > 10) {
        hero.style.pointerEvents = "none"
      } else {
        hero.style.pointerEvents = "auto"
      }
    }

    if (hero) {
      hero.style.pointerEvents = "auto"
      document.addEventListener("scroll", controlPointerEvents)
    }

    return () => {
      clearAnims()

      document.removeEventListener("scroll", controlPointerEvents)
    }
  }, [location, size.width, i18n.language])

  useEffect(() => {
    if (modalStore.open) {
      lockScrollbar()
      document.body.style.overflow = "hidden"
    } else {
      unlockScrollbar()
      document.body.style.overflow = "auto"
    }
  }, [modalStore.open])

  return (
    <div data-scroller ref={scrollerRef}>
      <div ref={contentRef}>{children}</div>
    </div>
  )
}

export { WithSmooth }
