import create from "zustand";

const useStore = create((set) => ({
  lastScrollY: {
    y: 0,
  },
  setLastY: (y) =>
    set((state) => (state.lastScrollY = { ...state.lastScrollY, y })),
}));

export const useLastScrollYStore = useStore;
