import React, { useEffect, useState } from "react"
import s from "../assets/scss/PinnedCardsTouch.module.scss"

import cx from "classnames"
import Img from "./Img"

import fam from "../assets/img/pinned-fam.jpg"
import leaf from "../assets/img/pinned-leaf.png"
import wave from "../assets/img/pinned-wave.png"
import bottle from "../assets/img/pinned-bottle.png"

import vegan1 from "../assets/img/pinned-vegan-1.svg"
import vegan2 from "../assets/img/pinned-vegan-2.svg"

import fam1 from "../assets/img/pinned-fam-1.svg"
import fam2 from "../assets/img/pinned-fam-2.svg"

import wave1 from "../assets/img/pinned-wave-1.svg"
import wave2 from "../assets/img/pinned-wave-2.svg"

import bottle1 from "../assets/img/pinned-bottle-1.svg"

import minus from "../assets/icon/minus.svg"
import plus from "../assets/icon/plus.svg"
import { Trans, useTranslation } from "react-i18next"
import { lngs } from "../types"

export default function PinnedCardsTouch() {
  const { t, i18n } = useTranslation()
  const [currentItem, setCurrentItem] = useState(null)

  const handleSelect = (index) => {
    if (currentItem === index) {
      return setCurrentItem(null)
    }

    setCurrentItem(index)
  }

  useEffect(() => {
    // if (currentItem !== null) {
    //   setTimeout(() => {
    //     document
    //       .querySelector(`[data-index="${currentItem}"]`)
    //       .scrollIntoView({ behavior: "smooth" });
    //   }, 1000);
    // }
  }, [currentItem])

  return (
    <div className={s.pinnedTouch}>
      <div className={s.itemsWrapper}>
        <div
          className={cx(s.itemWrapper, {
            [s.active]: currentItem === 0,
          })}
          data-index="0"
        >
          <div
            className={s.dropdownHeader}
            onClick={() => {
              handleSelect(0)
            }}
          >
            <h4 className={s.title}>{t("slider.s1.title")}</h4>
            <div className={s.iconsWrapper}>
              <div className={cx(s.iconWrapper, s.minus)}>
                <Img src={minus} objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.plus)}>
                <Img src={plus} objectFit="contain"></Img>
              </div>
            </div>
          </div>

          <div className={s.oWrapper}>
            <div
              className={cx(s.item, s.vegan, s.left)}
              onClick={() => {
                handleSelect(0)
              }}
            >
              <div className={s.content}>
                <p className={s.text}>{t("slider.s1.text.p1")}</p>
                <p className={s.text}>{t("slider.s1.text.p2")}</p>
                {/* <p className={s.indicator}>1/4</p> */}
              </div>
              <div className={s.imgWrapper}>
                <Img src={leaf} alt="Zoomed Leaf" objectFit="cover"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.vegan1)}>
                <Img src={vegan1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.vegan2)}>
                <Img src={vegan2} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx(s.itemWrapper, {
            [s.active]: currentItem === 1,
          })}
          data-index="1"
        >
          <div
            className={s.dropdownHeader}
            onClick={() => {
              handleSelect(1)
            }}
          >
            <h4 className={s.title}>
              <Trans key={"slider.s2.title"} components={{ br: <br /> }}>
                {t("slider.s2.title")}
              </Trans>
            </h4>
            <div className={s.iconsWrapper}>
              <div className={cx(s.iconWrapper, s.minus)}>
                <Img src={minus} objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.plus)}>
                <Img src={plus} objectFit="contain"></Img>
              </div>
            </div>
          </div>
          <div className={s.oWrapper}>
            <div
              className={cx(s.item, s.wave, s.right)}
              onClick={() => {
                handleSelect(1)
              }}
            >
              <div className={s.content}>
                <div className={s.titleWrapper}>
                  <div className={s.bg}></div>
                </div>
                <p className={s.text}>{t("slider.s2.text.p1")}</p>
                <p className={s.text}>{t("slider.s2.text.p2")}</p>
                <small className={s.small}>{t("slider.s2.small")} </small>
                {/* <div className={s.indicator}>
                  <p className={s.num}>2/4</p>
                </div> */}
              </div>
              <div className={s.imgWrapper}>
                <Img src={wave} alt="Purple Waves" objectFit="cover"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.wave1)}>
                <Img src={wave1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.wave2)}>
                <Img src={wave2} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx(s.itemWrapper, {
            [s.active]: currentItem === 2,
          })}
          data-index="2"
        >
          <div
            className={s.dropdownHeader}
            onClick={() => {
              handleSelect(2)
            }}
          >
            <h4 className={s.title}>{t("slider.s3.title")}</h4>
            <div className={s.iconsWrapper}>
              <div className={cx(s.iconWrapper, s.minus)}>
                <Img src={minus} objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.plus)}>
                <Img src={plus} objectFit="contain"></Img>
              </div>
            </div>
          </div>
          <div className={s.oWrapper}>
            <div
              className={cx(s.item, s.fam, s.left)}
              onClick={() => {
                handleSelect(2)
              }}
            >
              <div className={s.content}>
                <div className={s.titleWrapper}>
                  <div className={s.bg}></div>
                </div>
                <p className={s.text}>{t("slider.s3.text.p1")}</p>
                <p className={s.text}>{t("slider.s3.text.p2")}</p>
                {/* <div className={s.indicator}>
                  <p className={s.num}>3/4</p>
                </div> */}
              </div>
              <div className={s.imgWrapper}>
                <Img src={fam} alt="Family" objectFit="cover"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.fam1)}>
                <Img src={fam1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.fam2)}>
                <Img src={fam2} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx(s.itemWrapper, {
            [s.active]: currentItem === 3,
          })}
          data-index="3"
        >
          <div
            className={s.dropdownHeader}
            onClick={() => {
              handleSelect(3)
            }}
          >
            <h4 className={s.title}>{t("slider.s4.title")}</h4>
            <div className={s.iconsWrapper}>
              <div className={cx(s.iconWrapper, s.minus)}>
                <Img src={minus} objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.plus)}>
                <Img src={plus} objectFit="contain"></Img>
              </div>
            </div>
          </div>
          <div className={s.oWrapper}>
            <div
              className={cx(s.item, s.bottle, s.right)}
              onClick={() => {
                handleSelect(3)
              }}
            >
              <div className={s.content}>
                <div className={s.titleWrapper}>
                  <div className={s.bg}></div>
                </div>
                <p className={s.text}>{t("slider.s4.text.p1")}</p>
                <p className={s.text}>
                  {i18n.language === lngs.en.nativeName ? (
                    <>
                      Live, laugh, and <span className={s.underlineImg}>tiny green.</span>
                    </>
                  ) : (
                    <>
                      <span className={s.underlineImg}>tiny green</span> dünyasını keşfedin!
                    </>
                  )}{" "}
                </p>
                {/* <p className={s.indicator}>4/4</p> */}
              </div>
              <div className={cx(s.imgWrapper, s.bottles)}>
                <Img src={bottle} alt="Tiny Green Bottles" objectFit="cover"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.bottle1)}>
                <Img src={bottle1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
