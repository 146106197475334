export const breakpoints = {
  mobile: 640,
  tabletMini: 768,
  tablet: 1024,
  desktop: 1440,
  widescreen: 1920,
}

export const productCategories = {
  en: {
    all: { type: "all", ui: "All", path: "all" },
    adult: {
      type: "grown-ups",
      ui: "Grown-ups",
      path: "grown-ups",
    },
    kids: { type: "babies-and-kids", ui: "Babies & Kids", path: "babies-and-kids" },
  },
  tr: {
    all: { type: "tum-urunler", ui: "Tümü", path: "tum-urunler" },
    adult: {
      type: "yetiskin",
      ui: "Yetişkin",
      path: "yetiskin",
    },
    kids: { type: "bebek-ve-cocuk", ui: "Bebek & Çocuk", path: "bebek-ve-cocuk" },
  },
}

export const lngs = {
  en: { nativeName: "EN" },
  tr: { nativeName: "TR" },
}
