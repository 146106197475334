import create from "zustand"

const useStore = create((set, get) => ({
  open: false,
  content: null,
  toggle: () => set({ open: !get().open }),
  setContent: (content) => set({ content }),
}))

export const useModalStore = useStore
