import React from "react"
import s from "../assets/scss/Products.module.scss"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import useWindowSize from "../hooks/useWindowSize"
import { lngs } from "../types"
import ProductsList from "./ProductsList"

export default function Products() {
  const windowSize = useWindowSize()
  const { category } = useParams()
  const { i18n } = useTranslation()

  return (
    <div className={s.products} style={{ width: windowSize.width, height: windowSize.height }}>
      <ProductsList
        category={category ?? (i18n.language === lngs.en.nativeName ? "all" : "tum-urunler")}
      ></ProductsList>
    </div>
  )
}
