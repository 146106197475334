import React, { useCallback, useEffect, useRef } from "react"
import s from "../assets/scss/Hero.module.scss"

import cx from "classnames"
import Lottie from "lottie-react"

import hand from "../assets/img/hand.png"
import leaf from "../assets/img/leaf.svg"
import logoLight from "../assets/img/logo-tiny-green-light.svg"
import logo from "../assets/img/logo-tiny-green.svg"
import watsons from "../assets/img/only-on-watsons.svg"
import sponge from "../assets/img/sponge.png"
import stickerGreen from "../assets/img/sticker-green.svg"
import tinyText from "../assets/img/tinyText.svg"
import wood from "../assets/img/wood.png"

import * as _ from "lodash"

import { useTranslation } from "react-i18next"
import scrolldown from "../assets/lottie/hero-scrolldown.json"
import useWindowSize from "../hooks/useWindowSize"
import useHeroAnimationStore from "../stores/heroAnimationStore"
import { breakpoints } from "../types"
import Img from "./Img"

export default function Hero() {
  const { animated, play, reverse, toggle, lottieVisible, setLottie } = useHeroAnimationStore()
  // const [lottieVisible, setLottieVisible] = useState(false)
  const heroRef = useRef(null)
  const windowSize = useWindowSize()

  const animatedRef = useRef(false)
  const once = useRef(false)
  const running = useRef(false)
  const lastY = useRef(null)
  const currentY = useRef(null)

  const { t } = useTranslation()

  useEffect(() => {
    running.current = true
    // setLottie(false)

    if (animated) {
      animatedRef.current = true
    } else if (!animated) {
      animatedRef.current = false
    }
  }, [animated])

  useEffect(() => {
    initListeners()
    running.current = false

    const hero = heroRef.current

    return () => {
      hero.removeEventListener("wheel", preventDef)
      hero.removeEventListener("touchstart", preventDefTouch)

      window.removeEventListener("beforeunload", clearStorage)
      window.removeEventListener("unload", clearStorage)
    }
  }, [])

  const setCountdown = useCallback(() => {
    setTimeout(() => {
      enableScroll()
    }, 3000)
  }, [])

  const handleClick = () => {
    toggle()
    setCountdown()
  }

  const preventDef = (e) => {
    if (running.current) {
      return e?.preventDefault()
    }

    if (!once.current) {
      // console.log("once")
      once.current = true
      handleAnimation(e)

      // console.log("animation")
      // console.log("animated", animatedRef.current)
      // console.log("up", e.deltaY < 0)
      // console.log("down", e.deltaY > 0)
      // console.log("running", running.current)
    }
  }

  const preventDefTouch = (e) => {
    handleAnimationTouch(e)

    if (running.current || !animatedRef.current) {
      e?.preventDefault()
    }

    // console.log("animated", animatedRef.current);
    // console.log("up", e.deltaY < 0);
    // console.log("down", e.deltaY > 0);
    // console.log("running", running.current);
  }

  const handleAnimationTouch = (e) => {
    if (!once.current) {
      once.current = true
      setCountdown()
      currentY.current = e.touches[0].clientY

      if (currentY.current > lastY.current) {
        play()
      } else if (currentY.current < lastY.current) {
        reverse()
      }

      lastY.current = currentY.current
    }
  }

  const handleAnimation = useCallback(
    _.throttle(
      (e) => {
        setCountdown()

        if (e.deltaY > 0) {
          play()
        } else if (e.deltaY <= 0) {
          toggle()
        }
      },
      3000,
      { trailing: false }
    ),
    []
  )

  const initListeners = () => {
    if (windowSize.width > breakpoints.tablet) {
      heroRef.current.addEventListener("wheel", preventDef, {
        passive: false,
      })
    } else {
      heroRef.current.addEventListener("touchstart", preventDefTouch)
    }

    window.addEventListener("beforeunload", clearStorage)
    window.addEventListener("unload", clearStorage)
  }

  const enableScroll = () => {
    once.current = false
    running.current = false
    setLottie(true)
  }

  const clearStorage = () => {
    sessionStorage.clear()
  }

  return (
    <section className={cx(s.hero, { [s.animate]: animated })} data-hero ref={heroRef}>
      <a
        className={s.watsonsWrapper}
        href="https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.watsons.com.tr%2Ftiny-green%2Fb%2F3509%3Futm_source%3Dtinygreen%26utm_medium%3Dwebsite%26utm_campaign%3Dtinygreenref&data=05%7C01%7Carmagan.senol%40justdesignfx.com%7C16315560eb3a41666da108dbba7cfee4%7C5f834ae1f85348758ef24b6c5a7fda98%7C0%7C0%7C638308818311365241%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=jLi3Z%2B3XFGF%2BXzs4wc090SYNChmiT93xKt76pbcylJo%3D&reserved=0"
        target="_blank"
        rel="noreferrer noopener"
        style={{
          opacity: animated ? "0" : "1",
          transition: "200ms all ease",
        }}
      >
        <Img src={watsons} objectFit="contain"></Img>
      </a>

      <div className={s.logoWrapper} onClick={handleClick} data-tiny-logo>
        <div className={s.transitionWrapper}>
          <div className={s.logo}>
            <Img src={logo} objectFit="contain"></Img>
          </div>
          <div className={s.logoLight}>
            <Img src={logoLight} objectFit="contain"></Img>
          </div>
        </div>
      </div>

      <div className={s.tiny}>
        <div className={s.leafWrapper} onClick={handleClick}>
          <Img cls={s.tinyText} src={tinyText} alt="Tiny Green, Big Love"></Img>
          <Img cls={s.leaf} src={leaf} alt="Leaf"></Img>
        </div>
      </div>

      <div className={s.hello}>
        <div
          className={s.lottieWrapper}
          style={{
            opacity: lottieVisible ? "1" : "0",
            transition: "0.4s all ease",
          }}
        >
          <Lottie className={s.lottie} loop={true} animationData={scrolldown} autoPlay={true}></Lottie>
        </div>

        <div className={cx(s.stickerWrapper, s.hand)}>
          <Img src={hand} objectFit="contain"></Img>
        </div>

        <div className={cx(s.stickerWrapper, s.sponge)}>
          <Img src={sponge} objectFit="contain"></Img>
        </div>

        <div className={cx(s.stickerWrapper, s.wood)}>
          <Img src={wood} objectFit="contain"></Img>
        </div>

        <div className={s.textWrapper}>
          <div className={cx(s.line, s.sayHello)}>
            <h2 className={s.text}>{t("hero.l1")}</h2>
          </div>
          <div className={cx(s.line, s.vegan)}>
            <h2 className={s.text}>{t("hero.l2")}</h2>
          </div>
          <div className={cx(s.line, s.care)}>
            <h2 className={s.text}>{t("hero.l3")}</h2>
          </div>
          <div className={cx(s.line, s.adult)}>
            <h2 className={s.text}>{t("hero.l4")}</h2>
          </div>
        </div>

        <div className={s.bottomStickers}>
          <div className={s.sticker}>
            <Img src={stickerGreen} objectFit="contain"></Img>
          </div>
          <div className={s.sticker}>
            <Img src={stickerGreen} objectFit="contain"></Img>
          </div>
          <div className={s.sticker}>
            <Img src={stickerGreen} objectFit="contain"></Img>
          </div>
        </div>
      </div>
    </section>
  )
}
