import create from "zustand"
import { persist } from "zustand/middleware"

const useHeroAnimationStore = create(
  persist(
    (set, get) => ({
      animated: false,
      lottieVisible: false,
      play: () => set({ animated: true }),
      reverse: () => set({ animated: false }),
      toggle: (state) => set({ animated: !get().animated }),
      setLottie: (status) => set({ lottieVisible: status }),
    }),
    {
      name: "animationActivated",
      getStorage: () => sessionStorage,
    }
  )
)

export default useHeroAnimationStore
