import { useTranslation } from "react-i18next"
import s from "../assets/scss/PrivacyPopup.module.scss"
import React, { useEffect, useState } from "react"
import { lngs } from "../types"

const PrivacyPopup = () => {
  const { t, i18n } = useTranslation()
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const isAccepted = localStorage.getItem("isCookieAccepted") || ""

    if (isAccepted) {
      setPopupOpen(false)
    } else {
      setPopupOpen(true)
    }
  }, [])

  const handleClose = () => {
    setPopupOpen(false)
    localStorage.setItem("isCookieAccepted", JSON.stringify(true))
  }

  return (
    <>
      <div
        className={s.fixedWrapper}
        style={{
          transition: "0.4s all ease",
          opacity: popupOpen ? "1" : "0",
          visibility: popupOpen ? "visible" : "hidden",
          pointerEvents: popupOpen ? "auto" : "none",
        }}
      >
        <div className={s.cookieWrapper}>
          <p className={s.text}>
            {t("privacyPopup.text")}
            <br />{" "}
            <a
              style={{ textDecoration: "underline" }}
              href={
                i18n.language === lngs.en.nativeName
                  ? "https://thetinygreen.com/pdf/cerez-politikasi-en.pdf"
                  : "https://thetinygreen.com/pdf/cerez-politikasi-tr.pdf"
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("privacyPopup.cookiePolicy")}
            </a>
            .
          </p>
          <button className={s.btn} onClick={handleClose}>
            <p className={s.btnSuccess}>{t("privacyPopup.btnText")}</p>
          </button>
        </div>
      </div>
    </>
  )
}

export default PrivacyPopup
