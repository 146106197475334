import React, { useEffect } from "react"
import s from "../assets/scss/ProductsList.module.scss"

import cx from "classnames"
import Lottie from "lottie-react"
import { useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import scrolldown from "../assets/lottie/scrolldown.json"
import { breakpoints, lngs, productCategories } from "../types"

import useWindowSize from "../hooks/useWindowSize"
import HorizontalScroll from "./HorizontalScroll"
import ProductCard from "./ProductCard"

import babyMilk from "../assets/img/bottles/bottle-baby-milk.png"
import babyShampoo from "../assets/img/bottles/bottle-baby-shampoo.png"
import bodyLotion from "../assets/img/bottles/bottle-body-lotion.png"
import hairConditioner from "../assets/img/bottles/bottle-hair-conditioner.png"
import handCream from "../assets/img/bottles/bottle-hand-cream.png"
import handWash from "../assets/img/bottles/bottle-hand-wash.png"
import nappyRash from "../assets/img/bottles/bottle-nappy-rash.png"
import nippleCream from "../assets/img/bottles/bottle-nipple-cream.png"
import rinsingVinegar from "../assets/img/bottles/bottle-rinsing.png"
import shampooNourishing from "../assets/img/bottles/bottle-shampoo-nourishing.png"
import shampooPurifying from "../assets/img/bottles/bottle-shampoo-purifying.png"
import shampooVolume from "../assets/img/bottles/bottle-shampoo-volume.png"
import showerGel from "../assets/img/bottles/bottle-shower-gel.png"
import sunscreenFace from "../assets/img/bottles/bottle-sunscreen-face.png"
import sunscreenKids from "../assets/img/bottles/bottle-sunscreen-kids.png"
import sunscreen from "../assets/img/bottles/bottle-sunscreen.png"
import sticker50spf from "../assets/img/sticker-50-spf.svg"
import stickerAllHairEn from "../assets/img/sticker-all-hair-en.svg"
import stickerAllHairBlueTr from "../assets/img/sticker-all-hair-blue-tr.svg"
import stickerAllHairBlueEn from "../assets/img/sticker-all-hair-blue-en.svg"
import stickerAllHairTr from "../assets/img/sticker-all-hair-tr.svg"
import stickerDryHairEn from "../assets/img/sticker-dry-hair-en.svg"
import stickerDryHairTr from "../assets/img/sticker-dry-hair-tr.svg"
import stickerFineOilyHairEn from "../assets/img/sticker-fine-and-oily-hair-en.svg"
import stickerFineOilyHairTr from "../assets/img/sticker-fine-and-oily-hair-tr.svg"
import stickerNormalHairEn from "../assets/img/sticker-normal-hair-en.svg"
import stickerNormalHairTr from "../assets/img/sticker-normal-hair-tr.svg"
import watsons from "../assets/img/only-on-watsons.svg"

import Img from "./Img"

const productsTr = [
  {
    image: shampooVolume,
    label: <>GÜÇLÜ & PARLAK</>,
    title: <>ŞAMPUAN</>,
    small: <>500 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Elma Sirkesi</>, <>At Kuyruğu Ekstresi</>, <>Güçlendirici Formül</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerNormalHairTr,
    category: productCategories.tr.adult.type,
  },
  {
    image: shampooNourishing,
    label: <>BESLEYİCİ</>,
    title: <>ŞAMPUAN</>,
    small: <>500 ml</>,
    bgColor: "#F3F9F5",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Nemlendirici Formül</>, <>Argan Yağı</>, <>Besler & Parlatır</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerDryHairTr,
    category: productCategories.tr.adult.type,
  },
  {
    image: shampooPurifying,
    label: <>ARINDIRICI</>,
    title: <>ŞAMPUAN</>,
    small: <>500 ml</>,
    bgColor: "#FFFCEB",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [
        <>Güçlendirici Formül</>,
        <>Sarımsak Ekstresi</>,
        <>Ferahlatır & Besler</>,
        <>Paraben & Sülfat İçermez</>,
      ],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerFineOilyHairTr,
    category: productCategories.tr.adult.type,
  },
  {
    image: hairConditioner,
    label: null,
    title: (
      <>
        SAÇ <br /> KREMİ
      </>
    ),
    smTitle: true,
    small: <>250 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [
        <>Ekstra Parlaklık</>,
        <>At Kuyruğu Ekstresi</>,
        <>Nemlendirir & Yumuşatır</>,
        <>Paraben & Sülfat İçermez</>,
      ],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerAllHairTr,
    category: productCategories.tr.adult.type,
  },
  {
    image: rinsingVinegar,
    label: null,
    title: (
      <>
        SAÇ SİRKESİ <br /> & TONİĞİ
      </>
    ),
    smTitle: true,
    small: <>500 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Canlı & Parlak</>, <>Elma Sirkesi</>, <>Arındırıcı Formül</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerAllHairBlueTr,
    category: productCategories.tr.adult.type,
  },
  {
    image: showerGel,
    label: null,
    title: (
      <>
        DUŞ <br /> JELİ
      </>
    ),
    smTitle: true,
    small: <>500 ml</>,
    bgColor: "#FFFDEE",
    features: {
      textColor: "#155C45",
      bgColor: "#63D7EA",
      borderColor: "#63D7EA",
      highlightedItemIndex: 0,
      items: [
        <>Salatalık Kokulu</>,
        <>Canlandırıcı Meyveler</>,
        <>Temizler & Nemlendirir</>,
        <>Paraben & Sülfat İçermez</>,
      ],
    },
    stickerBottom: { textColor: "#63D7EA" },
    stickerTop: null,
    category: productCategories.tr.adult.type,
  },
  {
    image: bodyLotion,
    label: null,
    title: (
      <>
        VÜCUT <br /> LOSYONU
      </>
    ),
    smTitle: true,
    small: <>250 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#155C45",
      bgColor: "#5DBF95",
      borderColor: "#5DBF95",
      highlightedItemIndex: 0,
      items: [<>Salatalık Kokulu</>, <>Hyaluronik Asit & Yulaf</>, <>Hafif Formül</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#5DBF95" },
    stickerTop: null,
    category: productCategories.tr.adult.type,
  },
  {
    image: handWash,
    label: null,
    title: (
      <>
        SIVI EL <br /> SABUNU
      </>
    ),
    smTitle: true,
    small: <>500 ml</>,
    bgColor: "#FEF6F9",
    features: {
      textColor: "#006C57",
      bgColor: "#6FD1EF",
      borderColor: "#6FD1EF",
      highlightedItemIndex: 0,
      items: [<>Salatalık Kokulu</>, <>Çay Ağacı Yağı</>, <>Nemlendirici Formül</>, <>Paraben İçermez</>],
    },
    stickerBottom: { textColor: "#6FD1EF" },
    stickerTop: null,
    category: productCategories.tr.adult.type,
  },
  {
    image: handCream,
    imgRotation: -24,
    label: null,
    title: (
      <>
        NEMLENDİRİCİ <br /> EL KREMİ
      </>
    ),
    smTitle: true,
    small: <>50 ml</>,
    bgColor: "#FCF2F6",
    features: {
      textColor: "#006C57",
      bgColor: "#E3C0F8",
      borderColor: "#E3C0F8",
      highlightedItemIndex: 0,
      items: [<>Salatalık Kokulu</>, <>Avokado Yağı & Yulaf</>, <>Besleyici Formül</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#E3C0F8" },
    stickerTop: null,
    category: productCategories.tr.adult.type,
  },
  {
    image: sunscreen,
    imgRotation: -24,
    imgMarginBottom: "5%",
    label: null,
    title: (
      <>
        YETİŞKİN <br /> GÜNEŞ LOSYONU
      </>
    ),
    smTitle: true,
    small: <>150 ml</>,
    bgColor: "#FAEC10",
    features: {
      textColor: "#006C57",
      bgColor: "#F68E1E",
      borderColor: "#F68E1E",
      highlightedItemIndex: 1,
      items: [
        <>UVA + UVB | Mineral Filtre</>,
        <>Çok Yüksek Koruma</>,
        <>Yüz & Vücut</>,
        <>Çinko Oksit & Yulaf</>,
        <>Paraben & Sülfat İçermez</>,
      ],
    },
    stickerBottom: { textColor: "#F68E1E" },
    stickerTop: sticker50spf,
    category: productCategories.tr.adult.type,
  },
  {
    image: sunscreenFace,
    imgRotation: -24,
    imgMarginBottom: "5%",
    label: null,
    title: (
      <>
        YETİŞKİN YÜZ <br /> GÜNEŞ KREMİ
      </>
    ),
    smTitle: true,
    small: <>50 ml</>,
    bgColor: "#FAEC10",
    features: {
      textColor: "#006C57",
      bgColor: "#F68E1E",
      borderColor: "#F68E1E",
      highlightedItemIndex: 1,
      items: [
        <>UVA + UVB | Mineral Filtre</>,
        <>Çok Yüksek Koruma</>,
        <>Squalane</>,
        <>Niacinamide</>,
        <>Paraben & Sülfat İçermez</>,
      ],
    },
    stickerBottom: { textColor: "#F68E1E" },
    stickerTop: sticker50spf,
    category: productCategories.tr.adult.type,
  },
  {
    image: babyShampoo,
    label: <>BEBEK & ÇOCUK</>,
    title: <>ŞAMPUANI</>,
    small: <>500 ml / 250 ml</>,
    bgColor: "#F9E8F262",
    features: {
      textColor: "#006C57",
      bgColor: "#81D3F2",
      borderColor: "#81D3F2",
      highlightedItemIndex: 1,
      items: [<>Elma & Bambu Suyu</>, <>Papatya Kokulu</>, <>Saç & Vücut</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#81D3F2" },
    stickerTop: null,
    category: productCategories.tr.kids.type,
  },
  {
    image: babyMilk,
    label: null,
    title: (
      <>
        BEBEK <br /> VÜCUT SÜTÜ
      </>
    ),
    smTitle: true,
    small: <>250 ml</>,
    bgColor: "#E7F5FCD0",
    features: {
      textColor: "#006C57",
      bgColor: "#C398C6",
      borderColor: "#C398C6",
      highlightedItemIndex: 0,
      items: [<>Papatya Kokulu</>, <>Avokado Yağı & Yulaf</>, <>Nemlendirici Bakım</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#C398C6" },
    stickerTop: null,
    category: productCategories.tr.kids.type,
  },
  {
    image: nappyRash,
    smTitle: true,
    imgRotation: -26,
    label: null,
    title: (
      <>
        PİŞİK <br /> KREMİ
      </>
    ),
    small: <>50 ml</>,
    bgColor: "#F2CDD15F",
    features: {
      textColor: "#006C57",
      bgColor: "#BAA3DC",
      borderColor: "#BAA3DC",
      highlightedItemIndex: 1,
      items: [<>Çinko Oksit</>, <>Avokado Yağı & Yulaf</>, <>Nemlendirir & Korur</>, <>Paraben & Sülfat İçermez</>],
    },
    stickerBottom: { textColor: "#BAA3DC" },
    stickerTop: null,
    category: productCategories.tr.kids.type,
  },
  {
    image: nippleCream,
    imgRotation: -24,
    imgMarginBottom: "-5%",
    label: null,
    title: (
      <>
        MEME UCU <br /> KREMİ
      </>
    ),
    smTitle: true,
    small: <>40 ml</>,
    bgColor: "#FAECF5D0",
    features: {
      textColor: "#006C57",
      bgColor: "#FFB8E9",
      borderColor: "#FFB8E9",
      highlightedItemIndex: 1,
      items: [
        <>Avokado Yağı & Shea Butter</>,
        <>Besler & Yumuşatır</>,
        <>Gluten İçermez</>,
        <>Paraben & Sülfat İçermez</>,
      ],
    },
    stickerBottom: { textColor: "#FFB8E9" },
    stickerTop: null,
    category: productCategories.tr.kids.type,
  },
  {
    image: sunscreenKids,
    imgRotation: -24,
    imgMarginBottom: "5%",
    label: null,
    title: (
      <>
        ÇOCUK <br /> GÜNEŞ LOSYONU
      </>
    ),
    smTitle: true,
    small: <>150 ml</>,
    bgColor: "#FAEC10",
    features: {
      textColor: "#006C57",
      bgColor: "#F68E1E",
      borderColor: "#F68E1E",
      highlightedItemIndex: 1,
      items: [
        <>UVA + UVB | Mineral Filtre</>,
        <>Çok Yüksek Koruma</>,
        <>Yüz & Vücut</>,
        <>Çinko Oksit & Yulaf</>,
        <>Paraben & Sülfat İçermez</>,
      ],
    },
    stickerBottom: { textColor: "#F68E1E" },
    stickerTop: sticker50spf,
    category: productCategories.tr.kids.type,
  },
]

const productsEn = [
  {
    image: shampooVolume,
    label: <>VOLUME & SHINE</>,
    title: <>SHAMPOO</>,
    small: <>500 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Apple Cider Vinegar</>, <>Horsetail Extract</>, <>Strengthening</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerNormalHairEn,
    category: productCategories.en.adult.type,
  },
  {
    image: shampooNourishing,
    label: <>NOURISHING</>,
    title: <>SHAMPOO</>,
    small: <>500 ml</>,
    bgColor: "#F3F9F5",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Deep Moisture</>, <>Argan Oil</>, <>Nourish & Shine</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerDryHairEn,
    category: productCategories.en.adult.type,
  },
  {
    image: shampooPurifying,
    label: <>PURIFYING</>,
    title: <>SHAMPOO</>,
    small: <>500 ml</>,
    bgColor: "#FFFCEB",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Strengthening</>, <>Garlic Extract</>, <>Cleanse & Balance</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerFineOilyHairEn,
    category: productCategories.en.adult.type,
  },
  {
    image: hairConditioner,
    label: null,
    title: (
      <>
        HAIR <br /> CONDITIONER
      </>
    ),
    smTitle: true,
    small: <>250 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Soft & Shine</>, <>Horsetail Extract</>, <>Nourish & Repair</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerAllHairEn,
    category: productCategories.en.adult.type,
  },
  {
    image: rinsingVinegar,
    label: null,
    title: (
      <>
        RINSING <br /> VINEGAR
      </>
    ),
    smTitle: true,
    small: <>500 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#006C57",
      bgColor: "#9FCACE",
      borderColor: "#9FCACE",
      highlightedItemIndex: 1,
      items: [<>Refresh & Shine</>, <>Apple Cider Vinegar</>, <>Deep Cleanse</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#9FCACE" },
    stickerTop: stickerAllHairBlueEn,
    category: productCategories.en.adult.type,
  },
  {
    image: showerGel,
    label: null,
    title: (
      <>
        SHOWER <br /> GEL
      </>
    ),
    smTitle: true,
    small: <>500 ml</>,
    bgColor: "#FFFDEE",
    features: {
      textColor: "#155C45",
      bgColor: "#63D7EA",
      borderColor: "#63D7EA",
      highlightedItemIndex: 0,
      items: [<>Cucumber Scented</>, <>Invigorating Fruits</>, <>Cleanse & Moisture</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#63D7EA" },
    stickerTop: null,
    category: productCategories.en.adult.type,
  },
  {
    image: bodyLotion,
    label: null,
    title: (
      <>
        BODY <br /> LOTION
      </>
    ),
    smTitle: true,
    small: <>250 ml</>,
    bgColor: "#FFF5FF",
    features: {
      textColor: "#155C45",
      bgColor: "#5DBF95",
      borderColor: "#5DBF95",
      highlightedItemIndex: 0,
      items: [<>Cucumber Scented</>, <>Hyaluronic Acid & Oat</>, <>Lightweight Formula</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#5DBF95" },
    stickerTop: null,
    category: productCategories.en.adult.type,
  },
  {
    image: handWash,
    label: null,
    title: (
      <>
        HAND <br /> WASH
      </>
    ),
    smTitle: true,
    small: <>500 ml</>,
    bgColor: "#FEF6F9",
    features: {
      textColor: "#006C57",
      bgColor: "#6FD1EF",
      borderColor: "#6FD1EF",
      highlightedItemIndex: 0,
      items: [<>Cucumber Scented</>, <>Tea Tree Oil</>, <>Moisturizing Formula</>, <>Paraben Free</>],
    },
    stickerBottom: { textColor: "#6FD1EF" },
    stickerTop: null,
    category: productCategories.en.adult.type,
  },
  {
    image: handCream,
    imgRotation: -24,
    label: null,
    title: (
      <>
        HAND <br /> CREAM
      </>
    ),
    smTitle: true,
    small: <>50 ml</>,
    bgColor: "#FCF2F6",
    features: {
      textColor: "#006C57",
      bgColor: "#E3C0F8",
      borderColor: "#E3C0F8",
      highlightedItemIndex: 0,
      items: [<>Cucumber Scented</>, <>Avocado Oil & Oat</>, <>Nourishing Formula</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#E3C0F8" },
    stickerTop: null,
    category: productCategories.en.adult.type,
  },
  {
    image: sunscreen,
    imgRotation: -24,
    imgMarginBottom: "5%",
    label: null,
    title: (
      <>
        SUNSCREEN <br /> LOTION
      </>
    ),
    smTitle: true,
    small: <>150 ml</>,
    bgColor: "#FAEC10",
    features: {
      textColor: "#006C57",
      bgColor: "#F68E1E",
      borderColor: "#F68E1E",
      highlightedItemIndex: 1,
      items: [
        <>UVA + UVB | Mineral Filter</>,
        <>Very High Protection</>,
        <>Face & Body</>,
        <>Zinc Oxide & Oat</>,
        <>Paraben & Sulfate-Free</>,
      ],
    },
    stickerBottom: { textColor: "#F68E1E" },
    stickerTop: sticker50spf,
    category: productCategories.en.adult.type,
  },
  {
    image: sunscreenFace,
    imgRotation: -24,
    imgMarginBottom: "5%",
    label: null,
    title: (
      <>
        FACE <br /> SUNSCREEN
      </>
    ),
    smTitle: true,
    small: <>50 ml</>,
    bgColor: "#FAEC10",
    features: {
      textColor: "#006C57",
      bgColor: "#F68E1E",
      borderColor: "#F68E1E",
      highlightedItemIndex: 1,
      items: [
        <>UVA + UVB | Mineral Filter</>,
        <>Very High Protection</>,
        <>Squalane</>,
        <>Niacinamide</>,
        <>Paraben & Sulfate-Free</>,
      ],
    },
    stickerBottom: { textColor: "#F68E1E" },
    stickerTop: sticker50spf,
    category: productCategories.en.adult.type,
  },
  {
    image: babyShampoo,
    label: <>BABY & KIDS</>,
    title: <>SHAMPOO</>,
    small: <>500 ml / 250 ml</>,
    bgColor: "#F9E8F262",
    features: {
      textColor: "#006C57",
      bgColor: "#81D3F2",
      borderColor: "#81D3F2",
      highlightedItemIndex: 1,
      items: [<>Apple Juice & Bamboo</>, <>Chamomile Scented</>, <>Hair & Body</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#81D3F2" },
    stickerTop: null,
    category: productCategories.en.kids.type,
  },
  {
    image: babyMilk,
    label: null,
    title: (
      <>
        BABY <br /> BODY MILK
      </>
    ),
    smTitle: true,
    small: <>250 ml</>,
    bgColor: "#E7F5FCD0",
    features: {
      textColor: "#006C57",
      bgColor: "#C398C6",
      borderColor: "#C398C6",
      highlightedItemIndex: 0,
      items: [<>Chamomile Scented</>, <>Avocado Oil & Oat</>, <>Moisturizing Care</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#C398C6" },
    stickerTop: null,
    category: productCategories.en.kids.type,
  },
  {
    image: nappyRash,
    smTitle: true,
    imgRotation: -26,
    label: null,
    title: (
      <>
        NAPPY RASH <br /> CREAM
      </>
    ),
    small: <>50 ml</>,
    bgColor: "#F2CDD15F",
    features: {
      textColor: "#006C57",
      bgColor: "#BAA3DC",
      borderColor: "#BAA3DC",
      highlightedItemIndex: 1,
      items: [<>Zinc Oxide</>, <>Avocado Oil & Oat</>, <>Moisturizes & Protects</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#BAA3DC" },
    stickerTop: null,
    category: productCategories.en.kids.type,
  },
  {
    image: nippleCream,
    imgRotation: -24,
    imgMarginBottom: "-5%",
    label: null,
    title: (
      <>
        NIPPLE <br /> CREAM
      </>
    ),
    smTitle: true,
    small: <>40 ml</>,
    bgColor: "#FAECF5D0",
    features: {
      textColor: "#006C57",
      bgColor: "#FFB8E9",
      borderColor: "#FFB8E9",
      highlightedItemIndex: 1,
      items: [<>Avocado Oil & Shea Butter</>, <>Softens & Nourishes</>, <>Gluten-Free</>, <>Paraben & Sulfate-Free</>],
    },
    stickerBottom: { textColor: "#FFB8E9" },
    stickerTop: null,
    category: productCategories.en.kids.type,
  },
  {
    image: sunscreenKids,
    imgRotation: -24,
    imgMarginBottom: "5%",
    label: null,
    title: (
      <>
        KIDS SUNSCREEN <br /> LOTION
      </>
    ),
    smTitle: true,
    small: <>150 ml</>,
    bgColor: "#FAEC10",
    features: {
      textColor: "#006C57",
      bgColor: "#F68E1E",
      borderColor: "#F68E1E",
      highlightedItemIndex: 1,
      items: [
        <>UVA + UVB | Mineral Filter</>,
        <>Very High Protection</>,
        <>Face & Body</>,
        <>Zinc Oxide & Oat</>,
        <>Paraben & Sulfate-Free</>,
      ],
    },
    stickerBottom: { textColor: "#F68E1E" },
    stickerTop: sticker50spf,
    category: productCategories.en.kids.type,
  },
]

export default function ProductsList({ category = "all", ...props }) {
  const windowSize = useWindowSize()
  const closeRef = useRef(null)
  const { t, i18n } = useTranslation()
  const categories = i18n.language === lngs.en.nativeName ? productCategories.en : productCategories.tr
  const products = i18n.language === lngs.en.nativeName ? productsEn : productsTr

  const show = useCallback(() => {
    if (window.scrollY > 100) {
      closeRef.current.style.opacity = "1"
      closeRef.current.style.visibility = "visible"
    }
  }, [])

  const hide = () => {
    closeRef.current.style.opacity = "0"
    closeRef.current.style.visibility = "hidden"
  }

  useEffect(() => {
    // HIDE CLOSE WHEN SCROLL AT TOP
    if (closeRef.current && windowSize.width <= breakpoints.tablet) {
      hide()
    }
    window.addEventListener("scroll", show)

    return () => {
      window.removeEventListener("scroll", show)
    }
  }, [windowSize.width])

  return (
    <HorizontalScroll gradientBg={false}>
      <div className={s.filter}>
        {Object.entries(categories).map(([key, value]) => {
          return (
            <Link
              key={key}
              to={value.path}
              className={cx(s.title, {
                [s.adult]: category === categories.adult.type,
                [s.kids]: category === categories.kids.type,
                [s.all]: category === categories.all.type,
                [s.active]: category === value.type,
              })}
            >
              {value.ui}
            </Link>
          )
        })}
        <a
          className={s.watsonsWrapper}
          href="https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.watsons.com.tr%2Ftiny-green%2Fb%2F3509%3Futm_source%3Dtinygreen%26utm_medium%3Dwebsite%26utm_campaign%3Dtinygreenref&data=05%7C01%7Carmagan.senol%40justdesignfx.com%7C16315560eb3a41666da108dbba7cfee4%7C5f834ae1f85348758ef24b6c5a7fda98%7C0%7C0%7C638308818311365241%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=jLi3Z%2B3XFGF%2BXzs4wc090SYNChmiT93xKt76pbcylJo%3D&reserved=0"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Img src={watsons} objectFit="contain"></Img>
        </a>
        <a
          href={
            i18n.language === lngs.en.nativeName
              ? "https://thetinygreen.com/pdf/tinygreen-en-digital-catalogue.pdf"
              : "https://thetinygreen.com/pdf/tinygreen-tr-dijital-katalog.pdf"
          }
          target="_blank"
          rel="noreferrer noopener"
          className={cx(s.title, {
            [s.adult]: category === categories.adult.type,
            [s.kids]: category === categories.kids.type,
            [s.all]: category === categories.all.type,
          })}
        >
          {t("productDetail.downloadPdf")}
        </a>
      </div>

      <div className={s.lottieWrapper}>
        <Lottie className={s.lottie} loop={true} animationData={scrolldown} autoPlay={true}></Lottie>
      </div>

      <div
        ref={closeRef}
        className={s.close}
        data-close-btn
        style={{
          background: categories.adult.type === category ? "#016d58" : "#02cc89",
        }}
      >
        <Link to="/" className={s.link}>
          <span className={s.x}>x</span>
          {t("productDetail.close")}
        </Link>
      </div>

      <div className={s.panel} data-h-scroll-section>
        {products
          .filter((product) => {
            // console.log(product, product.category, category)
            if (
              category === "grown-ups" ||
              category === "yetiskin" ||
              category === "babies-and-kids" ||
              category === "bebek-ve-cocuk"
            ) {
              return product.category === category
            } else {
              return products
            }
          })
          .map((product, i) => {
            return <ProductCard product={product} key={i}></ProductCard>
          })}
      </div>

      <div className={s.scrollbar} data-hscroll-scrollbar>
        <div className={s.thumb} data-hscroll-scrollbar-thumb></div>
      </div>
    </HorizontalScroll>
  )
}
