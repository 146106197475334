import React, { useRef } from "react"
import s from "../assets/scss/TinyStep.module.scss"

import cx from "classnames"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { lngs } from "../types"
import Img from "./Img"

import bottleBodyMilk from "../assets/img/bottles/bottle-baby-milk.png"
import bottleShampoo from "../assets/img/bottles/bottle-shampoo-volume.png"
import bottleShowerGel from "../assets/img/bottles/bottle-shower-gel.png"
import bottleKidsSunscreen from "../assets/img/bottles/bottle-sunscreen-kids.png"

import arrowMarker from "../assets/img/horizontal/arrow-marker.svg"
import bgViewAllGreen from "../assets/img/horizontal/bg-view-all-green.svg"
import bgViewAll from "../assets/img/horizontal/bg-view-all.svg"
import doodleHeartSingle from "../assets/img/horizontal/doodle-heart-single.svg"
import doodleHeart from "../assets/img/horizontal/doodle-heart.svg"
import stickerDailyAdult from "../assets/img/horizontal/sticker-daily-adult.svg"

import stickerLoveKids from "../assets/img/horizontal/sticker-love-kids.svg"
import stickerTiny from "../assets/img/horizontal/sticker-tiny.svg"

import seperator from "../assets/img/seperator.svg"

export default function TinyStep() {
  const greetingRef = useRef(null)
  const { t, i18n } = useTranslation()

  return (
    <div className={s.tinyStep} data-tiny-step>
      <div className={cx(s.takeAStep)} ref={greetingRef}>
        <div className={s.textWrapper}>
          <div className={s.line}>
            <h2 className={s.text}>{t("takeAStep.p1")}</h2>
            <div className={s.iconWrapper}>
              <p className={s.tiny}>{t("takeAStep.rotating")}</p>
              <div className={s.icon}>
                <Img src={stickerTiny} objectFit="contain"></Img>
              </div>
            </div>
            <h2 className={s.text}>{t("takeAStep.p2")}</h2>
          </div>

          <div className={s.line}>
            <h2 className={s.text}>{t("takeAStep.p3")}</h2>
          </div>

          <div className={s.arrowWrapper} data-take-a-step-arrow>
            <Img src={arrowMarker} objectFit="contain"></Img>
          </div>
        </div>
      </div>

      <div className={cx(s.main, s.adult)}>
        <div className={s.titleWrapper}>
          <small className={s.hashtag}>#tinyforgrownups</small>

          <h1
            className={cx(s.title, {
              [s.en]: i18n.language === lngs.en.nativeName,
              [s.tr]: i18n.language === lngs.tr.nativeName,
            })}
          >
            {t("adult.title")}
          </h1>
        </div>

        <div className={s.stickerWrapper}>
          <Img src={stickerDailyAdult} objectFit="contain"></Img>
        </div>

        <div className={s.bottles}>
          <div className={s.bottleWrapper} data-parallax data-speed="0.3">
            <Img src={bottleShampoo} objectFit="contain"></Img>
          </div>

          <div className={s.bottleWrapper} data-parallax data-speed="0.1">
            <div className={s.t}>
              <Img src={bottleShowerGel} objectFit="contain"></Img>
            </div>
          </div>
        </div>
      </div>

      <div className={cx(s.detail, s.adult)}>
        <div className={s.textWrapper}>
          <h1 className={s.title}>{t("adult.pill")}</h1>
          <div className={s.descWrapper}>
            <h1 className={s.desc}>{t("adult.desc")}</h1>
          </div>
        </div>

        <Link
          className={s.btnWrapper}
          to={i18n.language === lngs.en.nativeName ? "/products/grown-ups" : "/urunler/yetiskin"}
          data-track-scroll-y
        >
          <div className={cx(s.bgWrapper, s.white)}>
            <p className={s.btnText}>
              <Trans key={"adult.button"} components={{ br: <br /> }}>
                {t("adult.button")}
              </Trans>
            </p>
            <Img src={bgViewAll}></Img>
          </div>
          <div className={cx(s.bgWrapper, s.green)}>
            <Img src={bgViewAllGreen}></Img>
          </div>
        </Link>

        <div className={s.seperatorWrapper}>
          <Img src={seperator} objectFit="contain"></Img>
        </div>
      </div>

      <div className={cx(s.main, s.babyAndKids)}>
        <div className={s.titleWrapper}>
          <div className={s.top}>
            <h1
              className={cx(s.titleSmall, {
                [s.en]: i18n.language === lngs.en.nativeName,
                [s.tr]: i18n.language === lngs.tr.nativeName,
              })}
            >
              {t("kids.title.p1")}
            </h1>
            <small className={s.hashtag}>#tinygreenlove</small>
            <div className={s.iconWrapper} data-parallax data-speed="0.1">
              <Img src={doodleHeartSingle} objectFit="contain"></Img>
            </div>
          </div>

          <h1
            className={cx(s.title, {
              [s.en]: i18n.language === lngs.en.nativeName,
              [s.tr]: i18n.language === lngs.tr.nativeName,
            })}
          >
            {t("kids.title.p2")}
          </h1>
        </div>

        <div className={s.bottles}>
          <div className={s.bottleWrapper} data-parallax data-speed="0.1">
            <Img src={bottleKidsSunscreen} objectFit="contain"></Img>
          </div>

          <div className={s.bottleWrapper} data-parallax data-speed="0.2">
            <div className={s.t}>
              <Img src={bottleBodyMilk} objectFit="contain"></Img>
            </div>
          </div>
        </div>

        <div className={s.stickerWrapper}>
          <Img src={stickerLoveKids} objectFit="contain"></Img>
        </div>
      </div>

      <div className={cx(s.detail, s.babyAndKids)}>
        <div className={s.textWrapper}>
          <h1 className={s.title}>{t("kids.pill")}</h1>
          <div className={s.descWrapper}>
            <h1 className={s.desc}>{t("kids.desc")}</h1>
          </div>
          <div className={s.iconWrapper} data-parallax data-speed="0.1">
            <Img src={doodleHeart} objectFit="contain"></Img>
          </div>
        </div>

        <Link
          className={s.btnWrapper}
          to={i18n.language === lngs.en.nativeName ? "/products/babies-and-kids" : "/urunler/bebek-ve-cocuk"}
          data-track-scroll-y
        >
          <div className={cx(s.bgWrapper, s.white)}>
            <p className={s.btnText}>
              <Trans key={"kids.button"} components={{ br: <br /> }}>
                {t("kids.button")}
              </Trans>
            </p>
            <Img src={bgViewAll}></Img>
          </div>
          <div className={cx(s.bgWrapper, s.green)}>
            <Img src={bgViewAllGreen}></Img>
          </div>
        </Link>

        <div className={s.seperatorWrapper}>
          <Img src={seperator} objectFit="contain"></Img>
        </div>
      </div>
    </div>
  )
}
