import React from "react";
import PinnedCards from "../components/PinnedCards";
import PinnedCardsTouch from "../components/PinnedCardsTouch";
import useWindowSize from "../hooks/useWindowSize";
import { breakpoints } from "../types";

export default function Pinned() {
  const windowSize = useWindowSize();

  return (
    <div>
      {windowSize.width <= breakpoints.tablet ? (
        <PinnedCardsTouch></PinnedCardsTouch>
      ) : (
        <PinnedCards></PinnedCards>
      )}
    </div>
  );
}
