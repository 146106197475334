import React from "react"

import cn from "classnames"
import { useTranslation } from "react-i18next"

import sample from "../assets/icon/play.svg"
import s from "../assets/scss/StickyWatch.module.scss"
import { useModalStore } from "../stores/modalStore"
import Img from "./Img"

const StickyWatch = () => {
  const modalStore = useModalStore()
  const { t } = useTranslation()

  function handleModal() {
    modalStore.setContent(
      <div className={s.modalContent}>
        <video
          className={s.video}
          width="1920"
          height="1080"
          controls
          autoPlay
          playsInline
          muted
          onClick={(e) => e.stopPropagation()}
        >
          <source
            src="https://player.vimeo.com/progressive_redirect/playback/883187027/rendition/1080p/file.mp4?loc=external&log_user=0&signature=4c03b806526ae3d1de639589947a84bbdbd002df101bcd9d3d4235bd723b3f10"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    )
    modalStore.toggle()
  }

  return (
    <div className={cn(s.stickyWatch)} onClick={handleModal} data-sticky-watch>
      <div className={s.imgC}>
        <Img src={sample} objectFit="cover" />
      </div>
      <div className={s.text}>{t("stickyButton")}</div>
    </div>
  )
}

export default StickyWatch
