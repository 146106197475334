import React, { useEffect, useState } from "react"
import s from "../assets/scss/LanguageSelect.module.scss"

import { gsap } from "gsap"
import down from "../assets/icon/down.svg"
import i18n from "../i18n"
import Img from "./Img"
import { useLanguageStore } from "../stores/languageStore"

export default function LanguageSelect() {
  const { lang, setLang } = useLanguageStore()
  // const [val, setval] = useState(i18n.language)

  useEffect(() => {
    // console.log(val, i18n.language)
    gsap.to("body", {
      opacity: 0,
      duration: 0.4,
      onComplete: () => {
        i18n.changeLanguage(lang)
        gsap.to("body", {
          opacity: 1,
          duration: 0.8,
          delay: 0.8,
        })
      },
    })
  }, [lang])

  return (
    <div className={s.selectWrapper}>
      <select
        className={s.langSelect}
        onChange={(e) => {
          setLang(e.target.value)
        }}
        value={lang}
      >
        <option value="EN">EN</option>
        <option value="TR">TR</option>
      </select>
      <div className={s.arrow}>
        <Img src={down} objectFit="contain" />
      </div>
    </div>
  )
}
