import React, { useState, useEffect, useRef } from "react"
import s from "../assets/scss/PinnedCards.module.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/slickReset.scss"

import Slider from "react-slick"
import cx from "classnames"

import Img from "./Img"
import fam from "../assets/img/pinned-fam.jpg"
import leaf from "../assets/img/pinned-leaf.png"
import wave from "../assets/img/pinned-wave.png"
import bottle from "../assets/img/pinned-bottle-wide.jpg"
import vegan1 from "../assets/img/pinned-vegan-1.svg"
import vegan2 from "../assets/img/pinned-vegan-2.svg"
import fam1 from "../assets/img/pinned-fam-1.svg"
import fam2 from "../assets/img/pinned-fam-2.svg"
import wave1 from "../assets/img/pinned-wave-1.svg"
import wave2 from "../assets/img/pinned-wave-2.svg"
import bottle1 from "../assets/img/pinned-bottle-1.svg"
import arrow from "../assets/icon/arrow.svg"
import { Trans, useTranslation } from "react-i18next"
import { lngs } from "../types"

export default function PinnedCards() {
  const sliderRef = useRef(null)
  const [selected, setSelected] = useState(0)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    sliderRef.current.slickGoTo(selected)
  }, [selected])

  const settings = {
    speed: 400,
    infinite: false,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: false,
    beforeChange: (current, next) => {
      setSelected(Math.ceil(next))
    },
  }

  return (
    <div className={s.cards}>
      <div className={s.nav}>
        <ul className={s.navItems}>
          <li
            className={cx(s.navItem, { [s.active]: selected === 0 })}
            onClick={() => {
              setSelected(0)
            }}
          >
            <p className={s.itemText}>{t("slider.s1.title")}</p>
            <div className={s.arrow}>
              <Img src={arrow} objectFit="contain"></Img>
            </div>
          </li>
          <li
            className={cx(s.navItem, { [s.active]: selected === 1 })}
            onClick={() => {
              setSelected(1)
            }}
          >
            <p className={s.itemText}>
              <Trans key={"slider.s2.title"} components={{ br: <br /> }}>
                {t("slider.s2.title")}
              </Trans>
            </p>
            <div className={s.arrow}>
              <Img src={arrow} objectFit="contain"></Img>
            </div>
          </li>
          <li
            className={cx(s.navItem, { [s.active]: selected === 2 })}
            onClick={() => {
              setSelected(2)
            }}
          >
            <p className={s.itemText}>{t("slider.s3.title")}</p>
            <div className={s.arrow}>
              <Img src={arrow} objectFit="contain"></Img>
            </div>
          </li>
          <li
            className={cx(s.navItem, { [s.active]: selected === 3 })}
            onClick={() => {
              setSelected(3)
            }}
          >
            <p className={s.itemText}>{t("slider.s4.title")}</p>
            <div className={s.arrow}>
              <Img src={arrow} objectFit="contain"></Img>
            </div>
          </li>
        </ul>
      </div>

      <div className={s.itemsWrapper}>
        <Slider {...settings} ref={sliderRef}>
          <div>
            <div className={cx(s.item, s.vegan, s.left)}>
              <div className={s.content}>
                {/* <h2 className={s.title}>I'm Vegan</h2> */}
                <p className={s.text}>{t("slider.s1.text.p1")}</p>
                <p className={s.text}>{t("slider.s1.text.p2")}</p>
              </div>
              <div className={s.imgWrapper}>
                <Img src={leaf} alt="Zoomed Leaf" objectFit="cover"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.vegan1)}>
                <Img src={vegan1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.vegan2)}>
                <Img src={vegan2} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>

          <div>
            <div className={cx(s.item, s.wave, s.right)}>
              <div className={s.content}>
                <div className={s.titleWrapper}>
                  {/* <h2 className={s.title}>I’m SLS, SLES & Paraben Free!</h2> */}
                  <div className={s.bg}></div>
                </div>
                <p className={s.text}> {t("slider.s2.text.p1")}</p>
                <p className={s.text}>{t("slider.s2.text.p2")}</p>
                <small className={s.small}>{t("slider.s2.small")}</small>
              </div>
              <div className={s.imgWrapper}>
                <Img src={wave} alt="Purple Waves" objectFit="cover"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.wave1)}>
                <Img src={wave1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.wave2)}>
                <Img src={wave2} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>

          <div>
            <div className={cx(s.item, s.fam, s.left)}>
              <div className={s.content}>
                <div className={s.titleWrapper}>
                  {/* <h2 className={s.title}>I’m Simple, Gentle & Fun</h2> */}
                  <div className={s.bg}></div>
                </div>
                <p className={s.text}>{t("slider.s3.text.p1")}</p>
                <p className={s.text}>{t("slider.s3.text.p2")}</p>
              </div>
              <div className={s.imgWrapper}>
                <Img src={fam} alt="Family" objectFit="cover"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.fam1)}>
                <Img src={fam1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
              <div className={cx(s.iconWrapper, s.fam2)}>
                <Img src={fam2} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>

          <div>
            <div className={cx(s.item, s.bottle, s.right)}>
              <div className={s.content}>
                <div className={s.titleWrapper}>
                  {/* <h2 className={s.title}>Embrace the tiny green spirit!</h2> */}
                  <div className={s.bg}></div>
                </div>
                <p className={s.text}>{t("slider.s4.text.p1")}</p>
                <p className={s.text}>
                  {i18n.language === lngs.en.nativeName ? (
                    <>
                      Live, laugh, and <span className={s.underlineImg}>tiny green.</span>
                    </>
                  ) : (
                    <>
                      <span className={s.underlineImg}>tiny green</span> dünyasını keşfedin!
                    </>
                  )}
                </p>
              </div>
              <div className={s.imgWrapper}>
                <Img src={bottle} alt="Purple Waves" objectFit="cover" objectPosition="0 15%"></Img>
              </div>

              <div className={cx(s.iconWrapper, s.bottle1)}>
                <Img src={bottle1} alt="Abstract Shape" objectFit="contain"></Img>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

// PINNED VERSION
/* <div className={s.pinned} data-pinned>
  <div className={s.itemsWrapper}>
    <div className={cx(s.item, s.vegan, s.left)} data-pinned-item>
      <div className={s.content}>
        <h2 className={s.title}>I'm Vegan</h2>
        <p className={s.text}>
          Because I love animals and we care about them! I’m lovingly and
          proudly free of any animal-derived ingredients.
        </p>
        <p className={s.text}>
          So we are committed to making products that could potentially hurt any
          of them.
        </p>
        <p className={s.indicator}>1/4</p>
      </div>
      <div className={s.imgWrapper}>
        <Img src={leaf} alt="Zoomed Leaf" objectFit="cover"></Img>
      </div>

      <div className={cx(s.iconWrapper, s.vegan1)}>
        <Img src={vegan1} alt="Abstract Shape" objectFit="contain"></Img>
      </div>
      <div className={cx(s.iconWrapper, s.vegan2)}>
        <Img src={vegan2} alt="Abstract Shape" objectFit="contain"></Img>
      </div>
    </div>

    <div className={cx(s.item, s.wave, s.right)} data-pinned-item>
      <div className={s.content}>
        <div className={s.titleWrapper}>
          <h2 className={s.title}>I’m SLS, SLES & Paraben Free!</h2>
          <div className={s.bg}></div>
        </div>
        <p className={s.text}>You won’t find SLS, SLES and paraben.</p>
        <p className={s.text}>
          My balanced, impactful formulas are free from most commonly used skin
          irritants and preservatives. So we keep them out! Bye-bye!
        </p>
        <small className={s.small}>
          * Except for our cleansing tea tree oil Hand Wash, which is still free
          from paraben.
        </small>
        <div className={s.indicator}>
          <p className={s.num}>2/4</p>
        </div>
      </div>
      <div className={s.imgWrapper}>
        <Img src={wave} alt="Purple Waves" objectFit="cover"></Img>
      </div>

      <div className={cx(s.iconWrapper, s.wave1)}>
        <Img src={wave1} alt="Abstract Shape" objectFit="contain"></Img>
      </div>
      <div className={cx(s.iconWrapper, s.wave2)}>
        <Img src={wave2} alt="Abstract Shape" objectFit="contain"></Img>
      </div>
    </div>

    <div className={cx(s.item, s.fam, s.left)} data-pinned-item>
      <div className={s.content}>
        <div className={s.titleWrapper}>
          <h2 className={s.title}>I’m Simple, Gentle & Fun</h2>
          <div className={s.bg}></div>
        </div>
        <p className={s.text}>
          Skin-loving, playful formulas that deliver real, effective results.
          I’m harvested with simple, plant-based, impactful extracts for little
          ones and grown-ups.
        </p>
        <p className={s.text}>
          Plus, I’m dermatologically tested! Try me and you will see!
        </p>
        <div className={s.indicator}>
          <p className={s.num}>3/4</p>
        </div>
      </div>
      <div className={s.imgWrapper}>
        <Img src={fam} alt="Family" objectFit="cover"></Img>
      </div>

      <div className={cx(s.iconWrapper, s.fam1)}>
        <Img src={fam1} alt="Abstract Shape" objectFit="contain"></Img>
      </div>
      <div className={cx(s.iconWrapper, s.fam2)}>
        <Img src={fam2} alt="Abstract Shape" objectFit="contain"></Img>
      </div>
    </div>

    <div className={cx(s.item, s.bottle, s.right)} data-pinned-item>
      <div className={s.content}>
        <div className={s.titleWrapper}>
          <h2 className={s.title}>Embrace the tiny green spirit!</h2>
          <div className={s.bg}></div>
        </div>
        <p className={s.text}>
          Let’s play. Essential, vegan, reliable. Say goodbye to boring products
          and say hello to my tiny, big world!
        </p>
        <p className={s.text}>
          Live, laugh, and <span className={s.underlineImg}>tiny green.</span>
        </p>
        <p className={s.indicator}>4/4</p>
      </div>
      <div className={s.imgWrapper}>
        <Img src={bottle} alt="Purple Waves" objectFit="cover"></Img>
      </div>

      <div className={cx(s.iconWrapper, s.bottle1)}>
        <Img src={bottle1} alt="Abstract Shape" objectFit="contain"></Img>
      </div>
    </div>
  </div>
</div>; */
