import gsap, { Power1 } from "gsap"
import { useEffect, useLayoutEffect, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import s from "./assets/scss/App.module.scss"

import FixedContainer from "./components/FixedContainer"
import Loading from "./components/Loading"
import Modal from "./components/Modal"
import PrivacyPopup from "./components/PrivacyPopup"
import Products from "./components/Products"
import { WithSmooth } from "./hocs/WithSmooth"
import Home from "./pages/Home"
import { useLanguageStore } from "./stores/languageStore"
import { getLang } from "./utils/"

function App() {
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const { setLang } = useLanguageStore()

  // useEffect(() => {
  //   if (localStorage.getItem("i18nextLng")) {
  //     localStorage.removeItem("i18nextLng")
  //   }
  // }, [])

  useEffect(() => {
    const transitionElements = Array.from(document.querySelectorAll("[data-route-transition]"))

    if (location !== displayLocation) {
      gsap.to(transitionElements, {
        autoAlpha: 0,
        duration: 0.4,
        ease: Power1.easeInOut,
        onComplete: () => {
          gsap.to(transitionElements, {
            autoAlpha: 1,
            duration: 0.4,
            delay: 0.4,
            ease: Power1.easeInOut,
          })
          setDisplayLocation(location)
        },
      })
    }
  }, [location, displayLocation])

  useLayoutEffect(() => {
    getLang().then((lang) => {
      if (!lang) return

      if (lang === "TR") {
        setLang("TR")
      } else {
        setLang("EN")
      }
    })
  }, [])

  return (
    <>
      <WithSmooth location={displayLocation}>
        <div className={s.app} data-route-transition>
          <Routes location={displayLocation}>
            <Route index element={<Home />}></Route>

            <Route path="products" element={<Products />}>
              <Route path=":category"></Route>
            </Route>

            <Route path="urunler" element={<Products />}>
              <Route path=":category"></Route>
            </Route>
          </Routes>
        </div>
      </WithSmooth>

      <FixedContainer></FixedContainer>
      <Modal />
      <Loading />
      <PrivacyPopup />
    </>
  )
}

export default App
