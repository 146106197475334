import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import { en } from "./en"
import { tr } from "./tr"
import { lngs } from "../types"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      lng: localStorage.getItem("i18nextLng") || lngs.tr.nativeName,
      fallbackLng: lngs.tr.nativeName,
      returnObjects: true,
      resources: {
        EN: en,
        TR: tr,
      },
    },
    (err, t) => {
      if (err) return console.log("something went wrong loading", err)
      t("key") // -> same as i18next.
    }
  )

export default i18n
