import React, { useCallback, useEffect, useState } from "react";
import s from "../assets/scss/Loading.module.scss";

import Lottie from "lottie-react";
import cx from "classnames";

import logo from "../assets/img/logo-tiny-green.svg";
import loading from "../assets/lottie/loading.json";
import Img from "./Img";

export default function Loading() {
  //const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const preventDef = useCallback((e) => {
    console.log("lalsd");
    e?.preventDefault();
  }, []);

  const up = () => {
    setLoaded(true);
    enableScroll();
  };

  const enableScroll = () => {
    document.removeEventListener("wheel", preventDef);
    document.removeEventListener("touchstart", preventDef);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    document.addEventListener("wheel", preventDef, {
      passive: false,
    });
    document.addEventListener("touchstart", preventDef, {
      passive: false,
    });
    document.body.style.overflow = "hidden";

    let img, c, tot;

    img = document.images;
    c = 0;
    tot = img.length;

    const imgLoaded = () => {
      c += 1;
      //setProgress(((100 / tot) * c) << 0);

      if (c === tot) return up();
      if (c === tot) return;
    };

    const counter = () => {
      for (var i = 0; i < tot; i++) {
        var tImg = new Image();
        tImg.onload = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src = img[i].src;
      }
    };

    counter();
  }, []);

  return (
    <div className={cx(s.loading, { [s.loaded]: loaded })}>
      <div className={s.logoWrapper}>
        <div className={s.logo}>
          <Img src={logo} objectFit="contain"></Img>
        </div>
      </div>
      <div className={s.lottieWrapper}>
        <Lottie
          className={s.lottie}
          loop={true}
          animationData={loading}
          autoPlay={true}
        ></Lottie>
      </div>
    </div>
  );
}
