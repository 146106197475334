import React from "react"
import s from "../assets/scss/Home.module.scss"

import cx from "classnames"

import Img from "../components/Img"

import dreiStickers from "../assets/img/drei-stickers.svg"
import leafBrush from "../assets/img/leaf-brush.png"
import tinyText from "../assets/img/tinyText.svg"

import Footer from "../components/Footer"
import Hero from "../components/Hero"
import LanguageSelect from "../components/LanguageSelect"
import TinyStep from "../components/TinyStep"
import Pinned from "../hocs/Pinned"
import useWindowSize from "../hooks/useWindowSize"
import useHeroAnimationStore from "../stores/heroAnimationStore"
import { breakpoints } from "../types"

export default function Home() {
  const windowSize = useWindowSize()
  const heroAnimationStore = useHeroAnimationStore()

  return (
    <main className={s.home}>
      <Hero></Hero>

      <div className={cx(s.lngC, { [s.hide]: heroAnimationStore.animated })}>
        <LanguageSelect />
      </div>

      <section className={s.details}>
        <div className={s.leafBrush}>
          <Img src={leafBrush}></Img>
        </div>

        <div className={s.dreiStickers}>
          <Img src={dreiStickers}></Img>
        </div>

        {windowSize.width > breakpoints.tablet ? (
          <div className={s.curvedMarquee}>
            <svg
              className={s.curvedWrapper}
              id="text-curve__container"
              viewBox="0 -100 760 250"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path id="text-curve" d="M.18 100.22c58-22 109-100 259-99 192-10 392 88 516 88" fill="none"></path>

              <text y="40" className="text-curve__text">
                <textPath
                  id="text-curve__path"
                  href="#text-curve"
                  startOffset="0"
                  data-curved-marquee
                  className={s.text}
                  fill="#016d58"
                >
                  tiny green, big love
                </textPath>
              </text>
            </svg>
          </div>
        ) : (
          <div className={s.imgWrapper}>
            <Img src={tinyText} objectFit="contain"></Img>
          </div>
        )}

        <Pinned></Pinned>
      </section>

      <section className={s.gradient}>
        <TinyStep />
        <Footer />
      </section>
    </main>
  )
}
