import React from "react"
import s from "../assets/scss/Modal.module.scss"
import { useModalStore } from "../stores/modalStore"

const Modal = () => {
  const modalStore = useModalStore()

  function handleClose() {
    console.log("lol")
    if (modalStore.open) modalStore.toggle()
  }

  return (
    <>
      {modalStore.open ? (
        <div className={s.modal} onClick={handleClose}>
          <div className={s.close}>X</div>
          <div className={s.content}>{modalStore.content}</div>
        </div>
      ) : null}
    </>
  )
}

export default Modal
